import { AppFacade } from './+state/app.facade';
import { filter } from 'rxjs/operators';
import { CookiesState } from './+state/app.reducer';
import { Provider, Inject, APP_INITIALIZER } from '@angular/core';
import {
  AUTOMATICALLY_TRACE_CORE_NG_METRICS,
  INSTRUMENTATION_ENABLED,
  DATA_COLLECTION_ENABLED,
} from '@angular/fire/performance';
import { APP_LOCAL_STORAGE_KEY, APP_STORAGE_KEYS } from './app.storage';
import { ALLOW_SENTRY } from '@clients/helpers';

export function initApplication(appFacade: AppFacade): Function {
  return () =>
    new Promise((resolve: (value?: unknown) => void) => {
      appFacade.startAppInitializer();
      appFacade.loadApp();
      appFacade.loaded$
        .pipe(filter((loaded: boolean) => loaded))
        .subscribe(() => resolve(true));
    });
}

export function cookiesAnalyzer(
  stateStorageKey: string,
  subStateKey: string[],
  requestKey: 'necessary' | 'functional' | 'performance' | 'personalization'
): boolean {
  const value: CookiesState = JSON.parse(
    localStorage.getItem(stateStorageKey) || '{}'
  )[subStateKey[0]];
  if (!value || !value.confirmed) {
    return false;
  }
  return !!value[requestKey];
}

export function necessaryCookiesAnalyzer(
  stateStorageKey: string,
  subStateKey: string[]
): boolean {
  return cookiesAnalyzer(stateStorageKey, subStateKey, 'necessary');
}

export function functionalCookiesAnalyzer(
  stateStorageKey: string,
  subStateKey: string[]
): boolean {
  return cookiesAnalyzer(stateStorageKey, subStateKey, 'functional');
}

export function performanceCookiesAnalyzer(
  stateStorageKey: string,
  subStateKey: string[]
): boolean {
  return cookiesAnalyzer(stateStorageKey, subStateKey, 'performance');
}

export function personalizationCookiesAnalyzer(
  stateStorageKey: string,
  subStateKey: string[]
): boolean {
  return cookiesAnalyzer(stateStorageKey, subStateKey, 'personalization');
}

export const INITIALIZER_PROVIDERS: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: initApplication,
    deps: [[new Inject(AppFacade)]],
    multi: true,
  },
  {
    provide: ALLOW_SENTRY,
    useFactory: performanceCookiesAnalyzer,
    deps: [APP_LOCAL_STORAGE_KEY, APP_STORAGE_KEYS],
  },
  {
    provide: AUTOMATICALLY_TRACE_CORE_NG_METRICS,
    useFactory: performanceCookiesAnalyzer,
    deps: [APP_LOCAL_STORAGE_KEY, APP_STORAGE_KEYS],
  },
  {
    provide: INSTRUMENTATION_ENABLED,
    useFactory: performanceCookiesAnalyzer,
    deps: [APP_LOCAL_STORAGE_KEY, APP_STORAGE_KEYS],
  },
  {
    provide: DATA_COLLECTION_ENABLED,
    useFactory: performanceCookiesAnalyzer,
    deps: [APP_LOCAL_STORAGE_KEY, APP_STORAGE_KEYS],
  },
];
