import { ApplicationRef, Injectable, Injector, Optional } from '@angular/core';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { take, tap, filter, first } from 'rxjs/operators';
import { NavigationService } from '../../navigation/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  private readonly COOKIE_NAME = '__bc_update_check__';

  private available$: BehaviorSubject<boolean>;

  private forcedUpdate: boolean = false;

  constructor(
    @Optional() private swUpdate: SwUpdate,
    private navigationService: NavigationService,
    private injector: Injector
  ) {
    this.available$ = new BehaviorSubject<boolean>(false);

    if (this.swUpdate && this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => this.available$.next(true));
    }
  }

  updateIfAvailable(alternateUrl?: string): Observable<boolean> {
    if (!this.swUpdate || !this.swUpdate.isEnabled) {
      return from([false]);
    }

    return this.available$.pipe(
      take(1),
      tap((available: boolean) => {
        if (available) {
          this.swUpdate
            .activateUpdate()
            .then(() => {
              if (alternateUrl) {
                this.navigationService.open(
                  `${this.navigationService.getOrigin()}${alternateUrl}`
                );
              } else {
                this.navigationService.reload();
              }
            })
            .catch(() => {
              console.error('Error activating update');
            });
        } else {
          this.stabilizeAppForUpdateCheck();
        }
      })
    );
  }

  forceUpdate() {
    if (this.forcedUpdate) {
      return;
    }
    this.forcedUpdate = true;

    if (!this.swUpdate || !this.swUpdate.isEnabled) {
      this.navigationService.reload();
      return;
    }

    this.stabilizeAppForUpdateCheck();
    this.available$
      .pipe(
        filter((available: boolean) => available),
        take(1)
      )
      .subscribe(() => this.updateIfAvailable);
  }

  private stabilizeAppForUpdateCheck() {
    // this is handled with cookies so that it works across page reloads
    // the advantage of cookies is the possibility of setting a expires time
    // to prevent a never updating deadlock
    if (this.getCookie() != '') {
      return;
    }

    const appRef = this.injector.get(ApplicationRef);
    if (appRef?.isStable) {
      appRef.isStable
        .pipe(first((stable: boolean) => stable))
        .subscribe((val) => this.checkForUpdate());
    } else {
      console.error(
        'Error finding appRef.isStable; Updating without stable app'
      );
      this.checkForUpdate();
    }
  }

  private checkForUpdate() {
    this.setUpdateCookie('true', 2);
    this.swUpdate
      .checkForUpdate()
      .then(() => this.deleteCookie())
      .catch(() => {
        console.error('Error checking for update');
        this.deleteCookie();
      })
      .finally(() => this.deleteCookie());
  }

  private setUpdateCookie(value: string, expireMinutes: number) {
    const now: Date = new Date();
    now.setTime(now.getTime() + expireMinutes * 60 * 1000); // 10 min from now
    const expires: string = `expires=${now.toUTCString()}`;
    document.cookie = `${this.COOKIE_NAME}=${value}; ${expires}; path=/`;
  }

  private deleteCookie() {
    this.setUpdateCookie('', -1);
  }

  private getCookie(): string {
    const cookieArray: Array<string> = document.cookie.split(';');
    const cookieArrayLength: number = cookieArray.length;
    const cookieName = `${this.COOKIE_NAME}=`;
    let c: string;

    for (let i: number = 0; i < cookieArrayLength; i += 1) {
      c = cookieArray[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
}
