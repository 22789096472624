import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ClearState } from './helper.actions';

@Injectable({ providedIn: 'root' })
export class HelperFacade {
  constructor(private store: Store<{}>) {}

  clearState() {
    this.store.dispatch(new ClearState());
  }
}
