import { Route } from '@angular/router';
import { MenuComponent } from './menu/menu.component';
import { LoginComponent } from './login/login.component';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import {
  redirectUnverifiedToLogin,
  redirectVerifiedToMenu,
} from '@clients/api';
import { UpdateGuard } from '@clients/helpers';
import { RenewSuccessComponent } from './renew-success/renew-success.component';
import { PlantSustainabilityPdfScreenComponent } from './plant-sustainability-pdf-screen/plant-sustainability-pdf-screen.component';

export const ROUTES: Route[] = [
  {
    path: '',
    canActivateChild: [UpdateGuard],
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectVerifiedToMenu },
      },
      {
        path: 'plantSustainabilityPdf',
        component: PlantSustainabilityPdfScreenComponent,
      },
      {
        path: 'menu',
        component: MenuComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnverifiedToLogin },
      },
      {
        path: 'renewal',
        component: RenewSuccessComponent,
      },
      {
        path: 'quotes',
        loadChildren: () =>
          import('@clients/quotes').then((mod) => mod.QuotesModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnverifiedToLogin },
      },
      {
        path: 'deliveries',
        loadChildren: () =>
          import('@clients/deliveries').then((mod) => mod.DeliveriesModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnverifiedToLogin },
      },
      {
        path: 'concerns',
        loadChildren: () =>
          import('@clients/concerns').then((mod) => mod.ConcernsModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnverifiedToLogin },
      },
      {
        path: 'sustainability',
        loadChildren: () =>
          import('@clients/sustainability').then(
            (mod) => mod.SustainabilityModule
          ),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnverifiedToLogin },
      },
      {
        path: 'filters',
        loadChildren: () =>
          import('@clients/filters').then((mod) => mod.FiltersModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnverifiedToLogin },
      },
      { path: '**', redirectTo: 'menu' },
    ],
  },
];
