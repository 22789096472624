import { Component } from '@angular/core';
import { CustomerFacade } from '@clients/customer';
import {
  CustomerGroupRead,
  LoginControllerService,
  CustomerGroupIconControllerService,
} from '@clients/api';
import { MatSelectChange } from '@angular/material/select';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SettingsComponent } from './settings/settings.component';
import { AppFacade } from '../+state/app.facade';
import { take } from 'rxjs/operators';

@Component({
  selector: 'clients-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  customersLoaded$: Observable<boolean>;
  selectedCustomer?: CustomerGroupRead;
  allCustomers: CustomerGroupRead[];
  customerIcon$?: Observable<any>;

  constructor(
    private router: Router,
    private angularFireAuth: AngularFireAuth,
    private bottomSheet: MatBottomSheet,
    private customergroupIconControllerService: CustomerGroupIconControllerService,
    private customerFacade: CustomerFacade,
    private appFacade: AppFacade,
    private loginControllerService: LoginControllerService
  ) {
    this.customersLoaded$ = this.customerFacade.customerGroupsLoaded$;
    this.allCustomers = [];

    this.customerFacade.selectedCustomer$.subscribe(
      (selected?: CustomerGroupRead) => {
        this.selectedCustomer = selected;

        if (selected) {
          this.customerIcon$ = this.customergroupIconControllerService.getIconUsingGET(
            {
              customergroup_id: selected.customergroup_id,
              max_height: 28, // = 50% of toolbar height
              quality: 100,
            }
          );
        }
      }
    );
    this.customerFacade.allCustomerGroups$.subscribe(
      (allCustomerGroups: CustomerGroupRead[]) =>
        (this.allCustomers = allCustomerGroups)
    );
  }

  selectCustomer(event: MatSelectChange) {
    this.customerFacade.selectCustomer(event.value);
  }

  logout() {
    this.loginControllerService.logoutUsingPOST({}).subscribe(
      () => this.firebaseLogout(),
      () => this.firebaseLogout()
    );
  }

  openSettings() {
    this.appFacade.userEmail$
      .pipe(take(1))
      .subscribe((email: string | undefined) => {
        this.bottomSheet.open(SettingsComponent, { data: email });
      });
  }

  private firebaseLogout() {
    this.angularFireAuth.signOut().then(() => this.router.navigate(['/login']));
  }
}
