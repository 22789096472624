/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface StateChangeAdd {
  project_erfq_no: string;
  state_from?: StateChangeAdd.StateFromEnum;
  state_to?: StateChangeAdd.StateToEnum;
  timestamp: Date;
}
export namespace StateChangeAdd {
  export type StateFromEnum = 'open' | 'closed' | 'canceled';
  export const StateFromEnum = {
    Open: 'open' as StateFromEnum,
    Closed: 'closed' as StateFromEnum,
    Canceled: 'canceled' as StateFromEnum,
  };
  export type StateToEnum = 'open' | 'closed' | 'canceled';
  export const StateToEnum = {
    Open: 'open' as StateToEnum,
    Closed: 'closed' as StateToEnum,
    Canceled: 'canceled' as StateToEnum,
  };
}
