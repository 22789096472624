/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DeliveryStock {
  customergroup_key: string;
  customerpartnumber: string;
  date?: Date;
  item_description: string;
  quantity: number;
  site: string;
  site_key: string;
  warehouse_type: DeliveryStock.WarehouseTypeEnum;
}
export namespace DeliveryStock {
  export type WarehouseTypeEnum = 'wip' | 'fg' | 'git';
  export const WarehouseTypeEnum = {
    Wip: 'wip' as WarehouseTypeEnum,
    Fg: 'fg' as WarehouseTypeEnum,
    Git: 'git' as WarehouseTypeEnum,
  };
}
