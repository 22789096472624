import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'clients-verify-email-card',
  templateUrl: './verify-email-card.component.html',
  styleUrls: ['./verify-email-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyEmailCardComponent {
  @Output() back: EventEmitter<void> = new EventEmitter<void>();
  @Output() resendEmail: EventEmitter<void> = new EventEmitter<void>();
}
