<mat-card *transloco="let t; read: 'app'">
  <mat-card-header>
    <mat-card-title>{{ t('login.verifyEmailCard.title') }}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    {{ t('login.verifyEmailCard.text') }}
  </mat-card-content>

  <mat-card-actions>
    <button mat-button (click)="back.emit()">
      {{ t('general.back') }}
    </button>
    <div fxFlex="1 1 auto"></div>
    <button mat-button (click)="resendEmail.emit()">
      {{ t('login.verifyEmailCard.resend') }}
    </button>
  </mat-card-actions>
</mat-card>
