/**
 * BusinessConnect
 * BusinessConnect
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type WarehouseType = 'Wip' | 'Fg' | 'Git';

export const WarehouseType = {
  Wip: 'Wip' as WarehouseType,
  Fg: 'Fg' as WarehouseType,
  Git: 'Git' as WarehouseType,
};
