import { Action } from '@ngrx/store';

export enum HelperActionTypes {
  ClearState = '[Helper] Clear State',
}

export class ClearState implements Action {
  readonly type = HelperActionTypes.ClearState;
}

export type HelperAction = ClearState;

export const fromHelperActions = { ClearState };
