import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationService } from '@clients/helpers';

@Component({
  selector: 'account-expired-dialog',
  templateUrl: './account-expired-dialog.component.html',
  styleUrls: ['./account-expired-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountExpiredDialogComponent {
  constructor(
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { email: string; message: string },
    private navigationService: NavigationService
  ) {}

  logout() {
    this.angularFireAuth.signOut().then(() => this.router.navigate(['/login']));
  }

  refresh() {
    this.navigationService.reload();
  }
}
