<ng-container *transloco="let t; read: 'app'">
  <shared-page-layout
    [headerRef]="headerRef"
    [subheaderRef]="subheaderRef"
    [stickyheaderRef]="stickyheaderRef"
    [contentRef]="contentRef"
    [footerRef]="footerRef"
    [loading]="loading"
    [loadingColor]="'accent'"
  >
  </shared-page-layout>

  <ng-template #headerRef>
    <mat-toolbar class="transparent"></mat-toolbar>
  </ng-template>

  <ng-template #subheaderRef>
    <div class="sub-header"></div>
  </ng-template>

  <ng-template #stickyheaderRef>
    <mat-toolbar color="accent" fxLayoutAlign="center center" class="title">
      {{ t('title') }}
    </mat-toolbar>
  </ng-template>

  <ng-template #contentRef>
    <div class="content-wrapper" fxLayout="column" fxLayoutGap="16px">
      <form
        [formGroup]="loginForm"
        fxLayout="column"
        (ngSubmit)="login()"
        *ngIf="
          (!credentials || !loginForm.hasError('46/unverified-email')) &&
          !passwordReset
        "
      >
        <mat-card>
          <mat-card-content fxLayout="column" fxLayoutGap="16px">
            <mat-form-field>
              <input
                matInput
                [placeholder]="t('login.email')"
                formControlName="email"
                type="email"
                autocomplete="username"
              />
              <mat-error
                *ngIf="loginForm.controls['email'].hasError('required')"
              >
                {{ t('general.mandatory') }}
              </mat-error>
              <mat-error
                *ngIf="
                  loginForm.controls['email'].hasError('auth/invalid-email')
                "
              >
                {{ t('login.invalidEmail') }}
              </mat-error>
              <mat-error
                *ngIf="
                  loginForm.controls['email'].hasError('auth/user-disabled')
                "
              >
                {{ t('login.userDisabled') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <input
                matInput
                [placeholder]="t('login.password')"
                formControlName="password"
                [type]="showPassword ? 'text' : 'password'"
                autocomplete="current-password"
              />
              <button
                mat-button
                matSuffix
                mat-icon-button
                type="button"
                (click)="showPassword = !showPassword"
              >
                <mat-icon>
                  {{ showPassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>
              </button>
              <mat-error
                *ngIf="loginForm.controls['password'].hasError('required')"
              >
                {{ t('general.mandatory') }}
              </mat-error>
              <mat-error
                *ngIf="
                  loginForm.controls['password'].hasError('auth/wrong-password')
                "
              >
                {{ t('login.wrongPassword') }}
              </mat-error>
              <mat-error
                *ngIf="
                  loginForm.controls['password'].hasError('auth/weak-password')
                "
              >
                {{ t('login.weakPassword') }}
              </mat-error>
            </mat-form-field>
          </mat-card-content>

          <mat-card-actions>
            <button mat-button type="submit" color="primary">
              {{ t('login.login') }}
            </button>
            <div fxFlex="1 1 auto"></div>
            <button
              mat-button
              type="button"
              (click)="passwordReset = !passwordReset"
            >
              <small>{{ t('login.forgotPassword') }}</small>
            </button>
          </mat-card-actions>
        </mat-card>
      </form>

      <clients-verify-email-card
        *ngIf="credentials && loginForm.hasError('46/unverified-email')"
        (back)="clearErrors()"
        (resendEmail)="sendEmailVerification()"
      ></clients-verify-email-card>

      <clients-password-reset
        *ngIf="passwordReset"
        [email]="loginForm.controls['email'].value"
        (back)="passwordReset = !passwordReset"
        (loading)="loading = $event"
      ></clients-password-reset>

      <ng-container *ngIf="cookiesState$ | async as cookiesState">
        <clients-cookie-policy
          #cookiePolicy
          *ngIf="!cookiesState.confirmed"
          [cookiesState]="cookiesState"
        ></clients-cookie-policy>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #footerRef>
    <small fxLayut fxLayoutAlign="center center" fxLayoutGap="16px">
      <span>{{ t('login.poweredBy') }}</span>
      <img src="/assets/imgs/gkn-pm-horizontal.svg" />
    </small>
  </ng-template>
</ng-container>
