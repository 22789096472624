import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { RatingControllerService, RatingRead } from '@clients/api';
import { map, mergeMap, take } from 'rxjs/operators';
import { StateErrorLogger } from '@clients/helpers';
import { Observable, EMPTY, from } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Action } from '@ngrx/store';
import { CustomerFacade } from '@clients/customer';
import {
  RatingActionTypes,
  RatingLoaded,
  LoadRating,
  SetRating,
  RatingSet,
  SetCustomerGroup,
} from './rating.actions';
import { RatingComponent } from '../rating/rating.component';
import { RatingPartialState } from './rating.reducer';

@Injectable()
export class RatingEffects {
  @Effect() routing$ = this.dataPersistence.navigation(RatingComponent, {
    run: (action: ActivatedRouteSnapshot) => {
      const initialLoads: Observable<Action> = from([
        new SetCustomerGroup(+action.params.customergroupId),
      ]);

      return this.customerFacade.getCustomergroupIdOrElse(
        (customergroupId: number) => {
          return +action.params.customergroupId !== customergroupId
            ? initialLoads
            : this.customerFacade.customerGroupsLoaded$.pipe(
                take(1),
                mergeMap((loaded: boolean) => (loaded ? EMPTY : initialLoads))
              );
        },
        () => initialLoads
      );
    },
  });

  @Effect() setCustomerGroup$ = this.dataPersistence.fetch(
    RatingActionTypes.SetCustomerGroup,
    {
      run: (action: SetCustomerGroup) => {
        this.customerFacade.selectCustomer(action.customergroupId);

        return from([
          //new LoadRating()
        ]);
      },

      onError: (action: SetCustomerGroup, error: any) => {
        StateErrorLogger.logEffectsError(action, error);
      },
    }
  );

  @Effect() loadRating$ = this.dataPersistence.fetch(
    RatingActionTypes.LoadRating,
    {
      run: (action: LoadRating) => {
        return this.customerFacade.getCustomergroupIdOrElse(
          (customergroupId: number) => {
            return this.ratingControllerService
              .getRatingUsingGET({
                customergroup_id: customergroupId,
                module: action.module,
              })
              .pipe(map((rating: RatingRead) => new RatingLoaded(rating)));
          }
        );
      },

      onError: (action: LoadRating, error: any) => {
        StateErrorLogger.logEffectsError(action, error);
      },
    }
  );

  @Effect() setRating$ = this.dataPersistence.pessimisticUpdate(
    RatingActionTypes.SetRating,
    {
      run: (action: SetRating) => {
        return this.customerFacade.getCustomergroupIdOrElse(
          (customergroupId: number) => {
            return this.ratingControllerService
              .updateRatingUsingPUT({
                customergroup_id: customergroupId,
                module: action.module,
                rating: {
                  value: action.value,
                },
              })
              .pipe(map((rating: RatingRead) => new RatingSet(rating)));
          }
        );
      },

      onError: (action: SetRating, error: any) => {
        StateErrorLogger.logEffectsError(action, error);
      },
    }
  );

  constructor(
    private dataPersistence: DataPersistence<RatingPartialState>,
    private ratingControllerService: RatingControllerService,
    private customerFacade: CustomerFacade
  ) {}
}
