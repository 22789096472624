import { NgModule, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RatingComponent } from './rating/rating.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RatingFacade } from './+state/rating.facade';
import { StoreModule, StoreConfig } from '@ngrx/store';
import { RatingAction } from './+state/rating.actions';
import { LocalStorageService, storageMetaReducer } from '@clients/helpers';
import { EffectsModule } from '@ngrx/effects';
import { RatingEffects } from './+state/rating.effects';
import * as fromRating from './+state/rating.reducer';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';

export const RATING_STORAGE_KEYS = new InjectionToken<
  keyof fromRating.RatingState[]
>('RatingStorageKeys');
export const RATING_LOCAL_STORAGE_KEY = new InjectionToken<string[]>(
  'RatingStorage'
);
export const RATING_CONFIG_TOKEN = new InjectionToken<
  StoreConfig<fromRating.RatingState, RatingAction>
>('RatingConfigToken');

export function getRatingConfig(
  saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService
) {
  return {
    metaReducers: [
      storageMetaReducer(saveKeys, localStorageKey, storageService),
    ],
  };
}

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatProgressBarModule,
    MatIconModule,
    MatSnackBarModule,
    MatToolbarModule,
    StoreModule.forFeature(
      fromRating.RATING_FEATURE_KEY,
      fromRating.reducer,
      RATING_CONFIG_TOKEN
    ),
    EffectsModule.forFeature([RatingEffects]),
    TranslocoModule,
  ],
  declarations: [RatingComponent],
  exports: [RatingComponent],
  providers: [
    RatingFacade,
    {
      provide: RATING_LOCAL_STORAGE_KEY,
      useValue: '__bc_rating_storage__',
    },
    { provide: RATING_STORAGE_KEYS, useValue: ['loactions'] },
    {
      provide: RATING_CONFIG_TOKEN,
      deps: [
        RATING_STORAGE_KEYS,
        RATING_LOCAL_STORAGE_KEY,
        LocalStorageService,
      ],
      useFactory: getRatingConfig,
    },
    { provide: TRANSLOCO_SCOPE, useValue: 'rating' },
  ],
})
export class RatingModule {}
