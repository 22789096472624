import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { Configuration } from './auto-gen';
import { Configuration as NetConfiguration } from './auto-gen-net';
import {
  configurationFactory,
  netConfigurationFactory,
} from './api/configuration-factory';
import { DeviceTokenInterceptor } from './firebase/device-token.interceptor';
import { AuthInterceptor } from './firebase/auth.interceptor';
import { FirebaseEnvironment } from './firebase/firebase-environment';
import { ENVIRONMENT, VersionInterceptor } from '@clients/helpers';
import { ErrorSnackbarInterceptor } from './error/error-snackbar.interceptor';
import { AccountExpiredDialogComponent } from './error/account-expired-dialog/account-expired-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [AccountExpiredDialogComponent],
  imports: [
    HttpClientModule,
    AngularFireModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFirePerformanceModule,
    MatDialogModule,
    MatButtonModule,
    FlexLayoutModule,
    TranslocoModule,
  ],
})
export class ApiModule {
  public static forRoot(
    environment: FirebaseEnvironment
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: Configuration,
          useFactory: configurationFactory,
          deps: [ENVIRONMENT],
        },
        {
          provide: NetConfiguration,
          useFactory: netConfigurationFactory,
          deps: [ENVIRONMENT],
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: VersionInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: DeviceTokenInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorSnackbarInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        // instead of calling AngularFireModule.initializeApp in imports
        // https://github.com/angular/angularfire2/blob/master/src/core/firebase.app.module.ts
        { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
      ],
    };
  }
}
