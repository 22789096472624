import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { LocationSelectComponent } from './location-select/location-select.component';
import { SingleCountryPipe } from './location-select/pipes/single-country.pipe';
import { SingleRegionPipe } from './location-select/pipes/single-region.pipe';
import { GlobalOnlyPipe } from './location-select/pipes/global-only.pipe';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TrendArrowComponent } from './trend-arrow/trend-arrow.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatProgressBarModule,
    TranslocoModule,
    MatSelectModule,
    MatToolbarModule,
    MatIconModule,
  ],
  declarations: [
    PageLayoutComponent,
    LocationSelectComponent,
    SingleCountryPipe,
    SingleRegionPipe,
    GlobalOnlyPipe,
    TrendArrowComponent,
  ],
  exports: [PageLayoutComponent, LocationSelectComponent, TrendArrowComponent],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'shared' }],
})
export class SharedModule {}
