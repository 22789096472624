<mat-toolbar
  color="primary"
  fxLayoutAlign="center center"
  *transloco="let t; scope: 'rating'"
>
  <mat-icon
    *ngFor="let star of stars; let index = index"
    [color]="star ? 'accent' : null"
    (click)="setRating(index)"
    class="clickable"
  >
    {{ star ? 'star' : 'star_border' }}
  </mat-icon>
</mat-toolbar>
