import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  setSavedState(state: any, localStorageKey: string) {
    localStorage.setItem(localStorageKey, JSON.stringify(state));
  }

  getSavedState(localStorageKey: string): any {
    const savedState: string | null = localStorage.getItem(localStorageKey);
    return savedState ? JSON.parse(savedState) : {};
  }
}
