/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { RatingAdd } from '../model/ratingAdd';
import { RatingRead } from '../model/ratingRead';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class RatingControllerService {
  protected basePath =
    'http://api.bc.gknpm.fortysix.world/business-connect-api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * deleteRating
   * @param customergroup_id customergroup_id
   * @param module module
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteRatingUsingDELETE(
    params: {
      customergroup_id: number;
      module: 'quoting' | 'delivery' | 'concerning';
      Authorization?: string;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteRatingUsingDELETE(
    params: {
      customergroup_id: number;
      module: 'quoting' | 'delivery' | 'concerning';
      Authorization?: string;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteRatingUsingDELETE(
    params: {
      customergroup_id: number;
      module: 'quoting' | 'delivery' | 'concerning';
      Authorization?: string;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteRatingUsingDELETE(
    params: {
      customergroup_id: number;
      module: 'quoting' | 'delivery' | 'concerning';
      Authorization?: string;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroup_id: number = params.customergroup_id;
    const module: 'quoting' | 'delivery' | 'concerning' = params.module;
    const Authorization: string | undefined = params.Authorization;

    if (customergroup_id === null || customergroup_id === undefined) {
      throw new Error(
        'Required parameter customergroup_id was null or undefined when calling deleteRatingUsingDELETE.'
      );
    }
    if (module === null || module === undefined) {
      throw new Error(
        'Required parameter module was null or undefined when calling deleteRatingUsingDELETE.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/customer-group/${encodeURIComponent(
        String(customergroup_id)
      )}/rating/${encodeURIComponent(String(module))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * getRating
   * @param customergroup_id customergroup_id
   * @param module module
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRatingUsingGET(
    params: {
      customergroup_id: number;
      module: 'quoting' | 'delivery' | 'concerning';
      Authorization?: string;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<RatingRead>;
  public getRatingUsingGET(
    params: {
      customergroup_id: number;
      module: 'quoting' | 'delivery' | 'concerning';
      Authorization?: string;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RatingRead>>;
  public getRatingUsingGET(
    params: {
      customergroup_id: number;
      module: 'quoting' | 'delivery' | 'concerning';
      Authorization?: string;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RatingRead>>;
  public getRatingUsingGET(
    params: {
      customergroup_id: number;
      module: 'quoting' | 'delivery' | 'concerning';
      Authorization?: string;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroup_id: number = params.customergroup_id;
    const module: 'quoting' | 'delivery' | 'concerning' = params.module;
    const Authorization: string | undefined = params.Authorization;

    if (customergroup_id === null || customergroup_id === undefined) {
      throw new Error(
        'Required parameter customergroup_id was null or undefined when calling getRatingUsingGET.'
      );
    }
    if (module === null || module === undefined) {
      throw new Error(
        'Required parameter module was null or undefined when calling getRatingUsingGET.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<RatingRead>(
      `${this.configuration.basePath}/customer-group/${encodeURIComponent(
        String(customergroup_id)
      )}/rating/${encodeURIComponent(String(module))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * updateRating
   * @param customergroup_id customergroup_id
   * @param module module
   * @param rating rating
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateRatingUsingPUT(
    params: {
      customergroup_id: number;
      module: 'quoting' | 'delivery' | 'concerning';
      rating: RatingAdd;
      Authorization?: string;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<RatingRead>;
  public updateRatingUsingPUT(
    params: {
      customergroup_id: number;
      module: 'quoting' | 'delivery' | 'concerning';
      rating: RatingAdd;
      Authorization?: string;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RatingRead>>;
  public updateRatingUsingPUT(
    params: {
      customergroup_id: number;
      module: 'quoting' | 'delivery' | 'concerning';
      rating: RatingAdd;
      Authorization?: string;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RatingRead>>;
  public updateRatingUsingPUT(
    params: {
      customergroup_id: number;
      module: 'quoting' | 'delivery' | 'concerning';
      rating: RatingAdd;
      Authorization?: string;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroup_id: number = params.customergroup_id;
    const module: 'quoting' | 'delivery' | 'concerning' = params.module;
    const rating: RatingAdd = params.rating;
    const Authorization: string | undefined = params.Authorization;

    if (customergroup_id === null || customergroup_id === undefined) {
      throw new Error(
        'Required parameter customergroup_id was null or undefined when calling updateRatingUsingPUT.'
      );
    }
    if (module === null || module === undefined) {
      throw new Error(
        'Required parameter module was null or undefined when calling updateRatingUsingPUT.'
      );
    }
    if (rating === null || rating === undefined) {
      throw new Error(
        'Required parameter rating was null or undefined when calling updateRatingUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<RatingRead>(
      `${this.configuration.basePath}/customer-group/${encodeURIComponent(
        String(customergroup_id)
      )}/rating/${encodeURIComponent(String(module))}`,
      rating,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
