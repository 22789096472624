import { ActionReducer } from '@ngrx/store';
import * as Sentry from '@sentry/browser';

export function logNgrx(rootReducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any) => {
    const newState = rootReducer(state, action);

    Sentry.addBreadcrumb({
      category: 'ngrx',
      type: 'debug',
      data: {
        action,
        newState,
      },
    });

    return newState;
  };
}
