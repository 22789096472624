/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { SiteContainer } from '../model/siteContainer';
import { SiteRead } from '../model/siteRead';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class SiteControllerService {
  protected basePath =
    'http://api.bc.gknpm.fortysix.world/business-connect-api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * deleteSite
   * @param site_key site_key
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteSiteUsingDELETE(
    params: { site_key: string; Authorization?: string },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteSiteUsingDELETE(
    params: { site_key: string; Authorization?: string },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteSiteUsingDELETE(
    params: { site_key: string; Authorization?: string },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteSiteUsingDELETE(
    params: { site_key: string; Authorization?: string },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const site_key: string = params.site_key;
    const Authorization: string | undefined = params.Authorization;

    if (site_key === null || site_key === undefined) {
      throw new Error(
        'Required parameter site_key was null or undefined when calling deleteSiteUsingDELETE.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/site/${encodeURIComponent(
        String(site_key)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * getSites
   * @param Authorization
   * @param customergroup_id customergroup_id
   * @param onlyHavingConcernData onlyHavingConcernData
   * @param onlyHavingPlantSustainabilityData onlyHavingPlantSustainabilityData
   * @param onlyHavingSustainabilityData onlyHavingSustainabilityData
   * @param site_id site_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSitesUsingGET(
    params: {
      Authorization?: string;
      customergroup_id?: number;
      onlyHavingConcernData?: boolean;
      onlyHavingPlantSustainabilityData?: boolean;
      onlyHavingSustainabilityData?: boolean;
      site_id?: number;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<SiteRead>>;
  public getSitesUsingGET(
    params: {
      Authorization?: string;
      customergroup_id?: number;
      onlyHavingConcernData?: boolean;
      onlyHavingPlantSustainabilityData?: boolean;
      onlyHavingSustainabilityData?: boolean;
      site_id?: number;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<SiteRead>>>;
  public getSitesUsingGET(
    params: {
      Authorization?: string;
      customergroup_id?: number;
      onlyHavingConcernData?: boolean;
      onlyHavingPlantSustainabilityData?: boolean;
      onlyHavingSustainabilityData?: boolean;
      site_id?: number;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<SiteRead>>>;
  public getSitesUsingGET(
    params: {
      Authorization?: string;
      customergroup_id?: number;
      onlyHavingConcernData?: boolean;
      onlyHavingPlantSustainabilityData?: boolean;
      onlyHavingSustainabilityData?: boolean;
      site_id?: number;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const Authorization: string | undefined = params.Authorization;
    const customergroup_id: number | undefined = params.customergroup_id;
    const onlyHavingConcernData: boolean | undefined =
      params.onlyHavingConcernData;
    const onlyHavingPlantSustainabilityData: boolean | undefined =
      params.onlyHavingPlantSustainabilityData;
    const onlyHavingSustainabilityData: boolean | undefined =
      params.onlyHavingSustainabilityData;
    const site_id: number | undefined = params.site_id;

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (customergroup_id !== undefined && customergroup_id !== null) {
      queryParameters = queryParameters.set(
        'customergroup_id',
        <any>customergroup_id
      );
    }
    if (onlyHavingConcernData !== undefined && onlyHavingConcernData !== null) {
      queryParameters = queryParameters.set(
        'onlyHavingConcernData',
        <any>onlyHavingConcernData
      );
    }
    if (
      onlyHavingPlantSustainabilityData !== undefined &&
      onlyHavingPlantSustainabilityData !== null
    ) {
      queryParameters = queryParameters.set(
        'onlyHavingPlantSustainabilityData',
        <any>onlyHavingPlantSustainabilityData
      );
    }
    if (
      onlyHavingSustainabilityData !== undefined &&
      onlyHavingSustainabilityData !== null
    ) {
      queryParameters = queryParameters.set(
        'onlyHavingSustainabilityData',
        <any>onlyHavingSustainabilityData
      );
    }
    if (site_id !== undefined && site_id !== null) {
      queryParameters = queryParameters.set('site_id', <any>site_id);
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<Array<SiteRead>>(
      `${this.configuration.basePath}/site`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * updateSites
   * @param sites sites
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateSitesUsingPUT(
    params: { sites: SiteContainer; Authorization?: string },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateSitesUsingPUT(
    params: { sites: SiteContainer; Authorization?: string },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateSitesUsingPUT(
    params: { sites: SiteContainer; Authorization?: string },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateSitesUsingPUT(
    params: { sites: SiteContainer; Authorization?: string },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const sites: SiteContainer = params.sites;
    const Authorization: string | undefined = params.Authorization;

    if (sites === null || sites === undefined) {
      throw new Error(
        'Required parameter sites was null or undefined when calling updateSitesUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/site`,
      sites,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
