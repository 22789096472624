import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RATING_FEATURE_KEY, RatingState } from './rating.reducer';
import { RatingRead } from '@clients/api';

// Lookup the 'rating' feature state managed by NgRx
const getState = createFeatureSelector<RatingState>(RATING_FEATURE_KEY);

const getRating = createSelector(getState, (state: RatingState):
  | RatingRead
  | undefined => (state ? state.rating : undefined));

export const ratingQuery = {
  getRating,
};
