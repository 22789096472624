import { Action } from '@ngrx/store';
import { RatingRead } from '@clients/api';

export enum RatingActionTypes {
  SetCustomerGroup = '[Rating] Set CustomerGroup',
  LoadRating = '[Rating] Load Rating',
  RatingLoaded = '[Rating] Rating Loaded',
  SetRating = '[Rating] Set Rating',
  RatingSet = '[Rating] Rating Set',
}

export class LoadRating implements Action {
  readonly type = RatingActionTypes.LoadRating;
  constructor(public module: 'quoting' | 'delivery' | 'concerning') {}
}

export class SetCustomerGroup implements Action {
  readonly type = RatingActionTypes.SetCustomerGroup;
  constructor(public customergroupId: number) {}
}

export class RatingLoaded implements Action {
  readonly type = RatingActionTypes.RatingLoaded;
  constructor(public rating: RatingRead) {}
}

export class SetRating implements Action {
  readonly type = RatingActionTypes.SetRating;
  constructor(
    public value: number,
    public module: 'quoting' | 'delivery' | 'concerning'
  ) {}
}

export class RatingSet implements Action {
  readonly type = RatingActionTypes.RatingSet;
  constructor(public rating: RatingRead) {}
}

export type RatingAction =
  | SetCustomerGroup
  | LoadRating
  | RatingLoaded
  | SetRating
  | RatingSet;

export const fromRatingActions = {
  SetCustomerGroup,
  LoadRating,
  RatingLoaded,
  SetRating,
  RatingSet,
};
