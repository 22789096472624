import { Injectable, Inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiEnvironment } from '../api/api-environment';
import { DeviceService, ENVIRONMENT } from '@clients/helpers';

@Injectable()
export class DeviceTokenInterceptor implements HttpInterceptor {
  constructor(
    private deviceService: DeviceService,
    @Inject(ENVIRONMENT) private environment: ApiEnvironment
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let newReq: HttpRequest<any> = req;
    if (
      req.url.startsWith(this.environment.api) ||
      req.url.startsWith(this.environment.netApi)
    ) {
      newReq = req.clone({
        setHeaders: {
          'Device-Token': this.deviceService.getDeviceToken(),
        },
      });
    }
    return next.handle(newReq);
  }
}
