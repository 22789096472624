/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { ConcernContainer } from '../model/concernContainer';
import { ConcernOverview } from '../model/concernOverview';
import { ConcernRead } from '../model/concernRead';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class ConcernControllerService {
  protected basePath =
    'http://api.bc.gknpm.fortysix.world/business-connect-api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * deleteConcern
   * @param concern_key concern_key
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteConcernUsingDELETE(
    params: { concern_key: string; Authorization?: string },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteConcernUsingDELETE(
    params: { concern_key: string; Authorization?: string },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteConcernUsingDELETE(
    params: { concern_key: string; Authorization?: string },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteConcernUsingDELETE(
    params: { concern_key: string; Authorization?: string },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const concern_key: string = params.concern_key;
    const Authorization: string | undefined = params.Authorization;

    if (concern_key === null || concern_key === undefined) {
      throw new Error(
        'Required parameter concern_key was null or undefined when calling deleteConcernUsingDELETE.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/concern/${encodeURIComponent(
        String(concern_key)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * getConcernOverview
   * @param customergroup_id customergroup_id
   * @param Authorization
   * @param siteId siteId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getConcernOverviewUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      siteId?: number;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ConcernOverview>;
  public getConcernOverviewUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      siteId?: number;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ConcernOverview>>;
  public getConcernOverviewUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      siteId?: number;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ConcernOverview>>;
  public getConcernOverviewUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      siteId?: number;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroup_id: number = params.customergroup_id;
    const Authorization: string | undefined = params.Authorization;
    const siteId: number | undefined = params.siteId;

    if (customergroup_id === null || customergroup_id === undefined) {
      throw new Error(
        'Required parameter customergroup_id was null or undefined when calling getConcernOverviewUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (siteId !== undefined && siteId !== null) {
      queryParameters = queryParameters.set('siteId', <any>siteId);
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<ConcernOverview>(
      `${this.configuration.basePath}/customergroup/${encodeURIComponent(
        String(customergroup_id)
      )}/concern/overview`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * getConcern
   * @param concern_id concern_id
   * @param customergroup_id customergroup_id
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getConcernUsingGET(
    params: {
      concern_id: number;
      customergroup_id: number;
      Authorization?: string;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ConcernRead>;
  public getConcernUsingGET(
    params: {
      concern_id: number;
      customergroup_id: number;
      Authorization?: string;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ConcernRead>>;
  public getConcernUsingGET(
    params: {
      concern_id: number;
      customergroup_id: number;
      Authorization?: string;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ConcernRead>>;
  public getConcernUsingGET(
    params: {
      concern_id: number;
      customergroup_id: number;
      Authorization?: string;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const concern_id: number = params.concern_id;
    const customergroup_id: number = params.customergroup_id;
    const Authorization: string | undefined = params.Authorization;

    if (concern_id === null || concern_id === undefined) {
      throw new Error(
        'Required parameter concern_id was null or undefined when calling getConcernUsingGET.'
      );
    }
    if (customergroup_id === null || customergroup_id === undefined) {
      throw new Error(
        'Required parameter customergroup_id was null or undefined when calling getConcernUsingGET.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<ConcernRead>(
      `${this.configuration.basePath}/customergroup/${encodeURIComponent(
        String(customergroup_id)
      )}/concern/${encodeURIComponent(String(concern_id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * getConcerns
   * @param customergroup_id customergroup_id
   * @param Authorization
   * @param is_escalated is_escalated
   * @param is_open is_open
   * @param siteId siteId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getConcernsUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      is_escalated?: boolean;
      is_open?: boolean;
      siteId?: number;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<ConcernRead>>;
  public getConcernsUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      is_escalated?: boolean;
      is_open?: boolean;
      siteId?: number;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ConcernRead>>>;
  public getConcernsUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      is_escalated?: boolean;
      is_open?: boolean;
      siteId?: number;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ConcernRead>>>;
  public getConcernsUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      is_escalated?: boolean;
      is_open?: boolean;
      siteId?: number;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroup_id: number = params.customergroup_id;
    const Authorization: string | undefined = params.Authorization;
    const is_escalated: boolean | undefined = params.is_escalated;
    const is_open: boolean | undefined = params.is_open;
    const siteId: number | undefined = params.siteId;

    if (customergroup_id === null || customergroup_id === undefined) {
      throw new Error(
        'Required parameter customergroup_id was null or undefined when calling getConcernsUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (is_escalated !== undefined && is_escalated !== null) {
      queryParameters = queryParameters.set('is_escalated', <any>is_escalated);
    }
    if (is_open !== undefined && is_open !== null) {
      queryParameters = queryParameters.set('is_open', <any>is_open);
    }
    if (siteId !== undefined && siteId !== null) {
      queryParameters = queryParameters.set('siteId', <any>siteId);
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<Array<ConcernRead>>(
      `${this.configuration.basePath}/customergroup/${encodeURIComponent(
        String(customergroup_id)
      )}/concern`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * updateAllConcerns
   * @param concerns concerns
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAllConcernsUsingPUT(
    params: { concerns: ConcernContainer; Authorization?: string },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateAllConcernsUsingPUT(
    params: { concerns: ConcernContainer; Authorization?: string },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateAllConcernsUsingPUT(
    params: { concerns: ConcernContainer; Authorization?: string },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateAllConcernsUsingPUT(
    params: { concerns: ConcernContainer; Authorization?: string },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const concerns: ConcernContainer = params.concerns;
    const Authorization: string | undefined = params.Authorization;

    if (concerns === null || concerns === undefined) {
      throw new Error(
        'Required parameter concerns was null or undefined when calling updateAllConcernsUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/concern`,
      concerns,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * updateConcernsOfCustomergroup
   * @param customergroup_key customergroup_key
   * @param concerns concerns
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateConcernsOfCustomergroupUsingPUT(
    params: {
      customergroup_key: string;
      concerns: ConcernContainer;
      Authorization?: string;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateConcernsOfCustomergroupUsingPUT(
    params: {
      customergroup_key: string;
      concerns: ConcernContainer;
      Authorization?: string;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateConcernsOfCustomergroupUsingPUT(
    params: {
      customergroup_key: string;
      concerns: ConcernContainer;
      Authorization?: string;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateConcernsOfCustomergroupUsingPUT(
    params: {
      customergroup_key: string;
      concerns: ConcernContainer;
      Authorization?: string;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroup_key: string = params.customergroup_key;
    const concerns: ConcernContainer = params.concerns;
    const Authorization: string | undefined = params.Authorization;

    if (customergroup_key === null || customergroup_key === undefined) {
      throw new Error(
        'Required parameter customergroup_key was null or undefined when calling updateConcernsOfCustomergroupUsingPUT.'
      );
    }
    if (concerns === null || concerns === undefined) {
      throw new Error(
        'Required parameter concerns was null or undefined when calling updateConcernsOfCustomergroupUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/customergroup/${encodeURIComponent(
        String(customergroup_key)
      )}/concern`,
      concerns,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * updateConcernsOfSite
   * @param site_key site_key
   * @param concerns concerns
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateConcernsOfSiteUsingPUT(
    params: {
      site_key: string;
      concerns: ConcernContainer;
      Authorization?: string;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateConcernsOfSiteUsingPUT(
    params: {
      site_key: string;
      concerns: ConcernContainer;
      Authorization?: string;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateConcernsOfSiteUsingPUT(
    params: {
      site_key: string;
      concerns: ConcernContainer;
      Authorization?: string;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateConcernsOfSiteUsingPUT(
    params: {
      site_key: string;
      concerns: ConcernContainer;
      Authorization?: string;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const site_key: string = params.site_key;
    const concerns: ConcernContainer = params.concerns;
    const Authorization: string | undefined = params.Authorization;

    if (site_key === null || site_key === undefined) {
      throw new Error(
        'Required parameter site_key was null or undefined when calling updateConcernsOfSiteUsingPUT.'
      );
    }
    if (concerns === null || concerns === undefined) {
      throw new Error(
        'Required parameter concerns was null or undefined when calling updateConcernsOfSiteUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/site/${encodeURIComponent(
        String(site_key)
      )}/concern`,
      concerns,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
