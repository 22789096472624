/**
 * BusinessConnect
 * BusinessConnect
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { ItemSustainabilityContainerModel } from '../model/itemSustainabilityContainerModel';
import { ItemSustainabilityDetailsModel } from '../model/itemSustainabilityDetailsModel';
import { ItemSustainabilityListModel } from '../model/itemSustainabilityListModel';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class ItemSustainabilityService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * MinLevel:BaseUser
   * @param customergroupId
   * @param customerpartNumber
   * @param Authorization
   * @param Device_Token
   * @param Client_Version
   * @param Firebase_Regtoken
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSustainabilityDetails(
    params: {
      customergroupId: number;
      customerpartNumber: string;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ItemSustainabilityDetailsModel>;
  public getSustainabilityDetails(
    params: {
      customergroupId: number;
      customerpartNumber: string;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ItemSustainabilityDetailsModel>>;
  public getSustainabilityDetails(
    params: {
      customergroupId: number;
      customerpartNumber: string;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ItemSustainabilityDetailsModel>>;
  public getSustainabilityDetails(
    params: {
      customergroupId: number;
      customerpartNumber: string;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroupId: number = params.customergroupId;
    const customerpartNumber: string = params.customerpartNumber;
    const Authorization: string | undefined = params.Authorization;
    const Device_Token: string | undefined = params.Device_Token;
    const Client_Version: string | undefined = params.Client_Version;
    const Firebase_Regtoken: string | undefined = params.Firebase_Regtoken;

    if (customergroupId === null || customergroupId === undefined) {
      throw new Error(
        'Required parameter customergroupId was null or undefined when calling getSustainabilityDetails.'
      );
    }
    if (customerpartNumber === null || customerpartNumber === undefined) {
      throw new Error(
        'Required parameter customerpartNumber was null or undefined when calling getSustainabilityDetails.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }
    if (Device_Token !== undefined && Device_Token !== null) {
      headers = headers.set('Device-Token', String(Device_Token));
    }
    if (Client_Version !== undefined && Client_Version !== null) {
      headers = headers.set('Client-Version', String(Client_Version));
    }
    if (Firebase_Regtoken !== undefined && Firebase_Regtoken !== null) {
      headers = headers.set('Firebase-Regtoken', String(Firebase_Regtoken));
    }

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // authentication (Device-Token) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Device-Token']
    ) {
      headers = headers.set(
        'Device-Token',
        this.configuration.apiKeys['Device-Token']
      );
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
      'application/xml',
      'text/xml',
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<ItemSustainabilityDetailsModel>(
      `${
        this.configuration.basePath
      }/api/v1.0/customer-group/${encodeURIComponent(
        String(customergroupId)
      )}/ItemSustainability/${encodeURIComponent(
        String(customerpartNumber)
      )}/details`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * MinLevel:BaseUser
   * @param customergroupId
   * @param siteId
   * @param customerpartNumber
   * @param Authorization
   * @param Device_Token
   * @param Client_Version
   * @param Firebase_Regtoken
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSustainabilityItems(
    params: {
      customergroupId: number;
      siteId?: number;
      customerpartNumber?: string;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<ItemSustainabilityListModel>>;
  public getSustainabilityItems(
    params: {
      customergroupId: number;
      siteId?: number;
      customerpartNumber?: string;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ItemSustainabilityListModel>>>;
  public getSustainabilityItems(
    params: {
      customergroupId: number;
      siteId?: number;
      customerpartNumber?: string;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ItemSustainabilityListModel>>>;
  public getSustainabilityItems(
    params: {
      customergroupId: number;
      siteId?: number;
      customerpartNumber?: string;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroupId: number = params.customergroupId;
    const siteId: number | undefined = params.siteId;
    const customerpartNumber: string | undefined = params.customerpartNumber;
    const Authorization: string | undefined = params.Authorization;
    const Device_Token: string | undefined = params.Device_Token;
    const Client_Version: string | undefined = params.Client_Version;
    const Firebase_Regtoken: string | undefined = params.Firebase_Regtoken;

    if (customergroupId === null || customergroupId === undefined) {
      throw new Error(
        'Required parameter customergroupId was null or undefined when calling getSustainabilityItems.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (siteId !== undefined && siteId !== null) {
      queryParameters = queryParameters.set('siteId', <any>siteId);
    }
    if (customerpartNumber !== undefined && customerpartNumber !== null) {
      queryParameters = queryParameters.set(
        'customerpartNumber',
        <any>customerpartNumber
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }
    if (Device_Token !== undefined && Device_Token !== null) {
      headers = headers.set('Device-Token', String(Device_Token));
    }
    if (Client_Version !== undefined && Client_Version !== null) {
      headers = headers.set('Client-Version', String(Client_Version));
    }
    if (Firebase_Regtoken !== undefined && Firebase_Regtoken !== null) {
      headers = headers.set('Firebase-Regtoken', String(Firebase_Regtoken));
    }

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // authentication (Device-Token) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Device-Token']
    ) {
      headers = headers.set(
        'Device-Token',
        this.configuration.apiKeys['Device-Token']
      );
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
      'application/xml',
      'text/xml',
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<Array<ItemSustainabilityListModel>>(
      `${
        this.configuration.basePath
      }/api/v1.0/customer-group/${encodeURIComponent(
        String(customergroupId)
      )}/ItemSustainability/list`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * MinLevel:WriteService
   * @param Authorization
   * @param Device_Token
   * @param Client_Version
   * @param Firebase_Regtoken
   * @param ItemSustainabilityContainerModel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAllSustainabilities(
    params: {
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
      ItemSustainabilityContainerModel?: ItemSustainabilityContainerModel;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateAllSustainabilities(
    params: {
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
      ItemSustainabilityContainerModel?: ItemSustainabilityContainerModel;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateAllSustainabilities(
    params: {
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
      ItemSustainabilityContainerModel?: ItemSustainabilityContainerModel;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateAllSustainabilities(
    params: {
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
      ItemSustainabilityContainerModel?: ItemSustainabilityContainerModel;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const Authorization: string | undefined = params.Authorization;
    const Device_Token: string | undefined = params.Device_Token;
    const Client_Version: string | undefined = params.Client_Version;
    const Firebase_Regtoken: string | undefined = params.Firebase_Regtoken;
    const ItemSustainabilityContainerModel:
      | ItemSustainabilityContainerModel
      | undefined = params.ItemSustainabilityContainerModel;

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }
    if (Device_Token !== undefined && Device_Token !== null) {
      headers = headers.set('Device-Token', String(Device_Token));
    }
    if (Client_Version !== undefined && Client_Version !== null) {
      headers = headers.set('Client-Version', String(Client_Version));
    }
    if (Firebase_Regtoken !== undefined && Firebase_Regtoken !== null) {
      headers = headers.set('Firebase-Regtoken', String(Firebase_Regtoken));
    }

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // authentication (Device-Token) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Device-Token']
    ) {
      headers = headers.set(
        'Device-Token',
        this.configuration.apiKeys['Device-Token']
      );
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/api/v1.0/ItemSustainability/all`,
      ItemSustainabilityContainerModel,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * MinLevel:WriteService
   * @param customergroupKey
   * @param Authorization
   * @param Device_Token
   * @param Client_Version
   * @param Firebase_Regtoken
   * @param ItemSustainabilityContainerModel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateSustainabilitiesOfCustomerGroup(
    params: {
      customergroupKey: string;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
      ItemSustainabilityContainerModel?: ItemSustainabilityContainerModel;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateSustainabilitiesOfCustomerGroup(
    params: {
      customergroupKey: string;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
      ItemSustainabilityContainerModel?: ItemSustainabilityContainerModel;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateSustainabilitiesOfCustomerGroup(
    params: {
      customergroupKey: string;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
      ItemSustainabilityContainerModel?: ItemSustainabilityContainerModel;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateSustainabilitiesOfCustomerGroup(
    params: {
      customergroupKey: string;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
      ItemSustainabilityContainerModel?: ItemSustainabilityContainerModel;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroupKey: string = params.customergroupKey;
    const Authorization: string | undefined = params.Authorization;
    const Device_Token: string | undefined = params.Device_Token;
    const Client_Version: string | undefined = params.Client_Version;
    const Firebase_Regtoken: string | undefined = params.Firebase_Regtoken;
    const ItemSustainabilityContainerModel:
      | ItemSustainabilityContainerModel
      | undefined = params.ItemSustainabilityContainerModel;

    if (customergroupKey === null || customergroupKey === undefined) {
      throw new Error(
        'Required parameter customergroupKey was null or undefined when calling updateSustainabilitiesOfCustomerGroup.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }
    if (Device_Token !== undefined && Device_Token !== null) {
      headers = headers.set('Device-Token', String(Device_Token));
    }
    if (Client_Version !== undefined && Client_Version !== null) {
      headers = headers.set('Client-Version', String(Client_Version));
    }
    if (Firebase_Regtoken !== undefined && Firebase_Regtoken !== null) {
      headers = headers.set('Firebase-Regtoken', String(Firebase_Regtoken));
    }

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // authentication (Device-Token) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Device-Token']
    ) {
      headers = headers.set(
        'Device-Token',
        this.configuration.apiKeys['Device-Token']
      );
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${
        this.configuration.basePath
      }/api/v1.0/ItemSustainability/customer-group/${encodeURIComponent(
        String(customergroupKey)
      )}`,
      ItemSustainabilityContainerModel,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * MinLevel:WriteService
   * @param siteKey
   * @param Authorization
   * @param Device_Token
   * @param Client_Version
   * @param Firebase_Regtoken
   * @param ItemSustainabilityContainerModel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateSustainabilitiesOfSite(
    params: {
      siteKey: string;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
      ItemSustainabilityContainerModel?: ItemSustainabilityContainerModel;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateSustainabilitiesOfSite(
    params: {
      siteKey: string;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
      ItemSustainabilityContainerModel?: ItemSustainabilityContainerModel;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateSustainabilitiesOfSite(
    params: {
      siteKey: string;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
      ItemSustainabilityContainerModel?: ItemSustainabilityContainerModel;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateSustainabilitiesOfSite(
    params: {
      siteKey: string;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
      ItemSustainabilityContainerModel?: ItemSustainabilityContainerModel;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const siteKey: string = params.siteKey;
    const Authorization: string | undefined = params.Authorization;
    const Device_Token: string | undefined = params.Device_Token;
    const Client_Version: string | undefined = params.Client_Version;
    const Firebase_Regtoken: string | undefined = params.Firebase_Regtoken;
    const ItemSustainabilityContainerModel:
      | ItemSustainabilityContainerModel
      | undefined = params.ItemSustainabilityContainerModel;

    if (siteKey === null || siteKey === undefined) {
      throw new Error(
        'Required parameter siteKey was null or undefined when calling updateSustainabilitiesOfSite.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }
    if (Device_Token !== undefined && Device_Token !== null) {
      headers = headers.set('Device-Token', String(Device_Token));
    }
    if (Client_Version !== undefined && Client_Version !== null) {
      headers = headers.set('Client-Version', String(Client_Version));
    }
    if (Firebase_Regtoken !== undefined && Firebase_Regtoken !== null) {
      headers = headers.set('Firebase-Regtoken', String(Firebase_Regtoken));
    }

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // authentication (Device-Token) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Device-Token']
    ) {
      headers = headers.set(
        'Device-Token',
        this.configuration.apiKeys['Device-Token']
      );
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${
        this.configuration.basePath
      }/api/v1.0/ItemSustainability/site/${encodeURIComponent(
        String(siteKey)
      )}`,
      ItemSustainabilityContainerModel,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
