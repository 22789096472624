import { Injectable } from '@angular/core';
import {
  Effect,
  OnRunEffects,
  EffectNotification,
  Actions,
  ofType,
} from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { AppPartialState } from './app.reducer';
import { AppLoaded, AppActionTypes } from './app.actions';
import { MenuComponent } from '../menu/menu.component';
import { LoginComponent } from '../login/login.component';
import { HelperFacade } from '@clients/helpers';
import { CustomerFacade } from '@clients/customer';
import { Observable } from 'rxjs';
import { exhaustMap, takeUntil } from 'rxjs/operators';

@Injectable()
export class AppEffects implements OnRunEffects {
  @Effect() routingLogin$ = this.dataPersistence.navigation(LoginComponent, {
    run: () => {
      this.helperFacade.clearState();
    },
  });

  @Effect() routingMenu$ = this.dataPersistence.navigation(MenuComponent, {
    run: () => {
      this.customerFacade.loadAll();
    },
  });

  @Effect() loadApp$ = this.dataPersistence.fetch(AppActionTypes.LoadApp, {
    run: () => {
      return new AppLoaded();
    },
  });

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<AppPartialState>,
    private helperFacade: HelperFacade,
    private customerFacade: CustomerFacade
  ) {}

  ngrxOnRunEffects(
    resolvedEffects$: Observable<EffectNotification>
  ): Observable<EffectNotification> {
    return this.actions.pipe(
      ofType(AppActionTypes.StartAppInitializer),
      exhaustMap(() =>
        resolvedEffects$.pipe(
          takeUntil(
            this.actions.pipe(ofType(AppActionTypes.FinishAppInitializer))
          )
        )
      )
    );
  }
}
