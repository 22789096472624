import { Component, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { LoginControllerService, WhoAmI } from '@clients/api';
import { DeviceService } from '@clients/helpers';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { AppFacade } from '../+state/app.facade';
import { CookiesState } from '../+state/app.reducer';
import { Observable } from 'rxjs';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import firebase from 'firebase';
import UserCredential = firebase.auth.UserCredential;

@Component({
  selector: 'clients-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  @ViewChild(CookiePolicyComponent, { static: false })
  private cookiePolicyRef?: CookiePolicyComponent;

  cookiesState$: Observable<CookiesState>;
  loginForm: FormGroup;
  showPassword = false;
  loading = false;
  credentials?: UserCredential;
  passwordReset = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private angularFireAuth: AngularFireAuth,
    private snackbar: MatSnackBar,
    private transloco: TranslocoService,
    private loginControllerService: LoginControllerService,
    private deviceService: DeviceService,
    private appFacade: AppFacade
  ) {
    this.cookiesState$ = this.appFacade.cookiesState$;

    this.loginForm = this.formBuilder.group({
      email: this.formBuilder.control(
        null,
        Validators.compose([Validators.required])
      ),
      password: this.formBuilder.control(
        null,
        Validators.compose([Validators.required])
      ),
    });
  }

  async login() {
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    const email: string = this.loginForm.controls.email.value;
    const password: string = this.loginForm.controls.password.value;
    try {
      this.credentials = await this.angularFireAuth.signInWithEmailAndPassword(
        email,
        password
      );

      if (this.credentials.user?.emailVerified) {
        this.loginControllerService
          .loginUsingPOST({
            deviceInfo: {
              device_name: this.deviceService.deviceName,
              is_browser: this.deviceService.isBrowser,
              is_mobile: this.deviceService.isMobile,
              is_virtual: false,
            },
          })
          .subscribe((user: WhoAmI) => {
            if (this.cookiePolicyRef) {
              this.cookiePolicyRef.confirm();
            }

            this.appFacade.setEmail(user.email);
            this.router.createUrlTree([], {});
            this.router.navigate(['/menu']);
            this.loading = false;
          });
      } else {
        this.loginForm.setErrors({
          '46/unverified-email': true,
        });
        this.loading = false;
      }
    } catch (err) {
      this.handleError(err, email, password);
      this.loading = false;
    }
  }

  async sendEmailVerification() {
    if (!this.credentials) {
      return;
    }

    this.loading = true;
    try {
      await this.credentials.user?.sendEmailVerification();
      this.snackbar.open(
        this.transloco.translate('app.login.verifyEmailSent'),
        this.transloco.translate('app.general.close'),
        {
          duration: 3000,
        }
      );
    } catch (err) {
      this.handleError(err);
    }
    this.loading = false;
  }

  clearErrors() {
    this.loginForm.setErrors(null);
    this.loginForm.controls.email.setErrors(null);
    this.loginForm.controls.password.setErrors(null);
  }

  private async register(email: string, password: string) {
    try {
      this.credentials = await this.angularFireAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      this.sendEmailVerification();
      this.loginForm.setErrors({
        '46/unverified-email': true,
      });
    } catch (err) {
      this.handleError(err, email, password);
    }
    this.loading = false;
  }

  private handleError(err: any, email?: string, password?: string) {
    switch (err.code) {
      // Thrown if the email address is not valid.
      case 'auth/invalid-email': {
        this.loginForm.controls.email.setErrors({
          'auth/invalid-email': true,
        });
        break;
      }
      // Thrown if the user corresponding to the given email has been disabled.
      case 'auth/user-disabled': {
        this.loginForm.controls.email.setErrors({
          'auth/user-disabled': true,
        });
        break;
      }
      // Thrown if there is no user corresponding to the given email.
      case 'auth/user-not-found': {
        if (email && password) {
          this.register(email, password);
        }
        break;
      }
      // Thrown if the password is invalid for the given email, or the account corresponding to the email does not have a password set.
      case 'auth/wrong-password': {
        this.loginForm.controls.password.setErrors({
          'auth/wrong-password': true,
        });
        break;
      }
      // Thrown if the password is not strong enough.
      case 'auth/weak-password': {
        this.loginForm.controls.password.setErrors({
          'auth/weak-password': true,
        });
        break;
      }
      default: {
        console.error(`46 unhandled: ${err}`);
      }
    }
  }
}
