<mat-card *transloco="let t; read: 'app.login.cookiePolicy'">
  <mat-card-content>
    <p>{{ t('description') }}</p>
    <a href="">{{ t('link') }}</a>

    <div fxLayout fxLayoutGap="16px">
      <mat-list>
        <div
          fxLayout
          fxLayoutAlign="start center"
          fxLayoutGap="16px"
          class="clickable"
          (click)="handleClick('necessary')"
        >
          <img
            [src]="
              '/assets/imgs/custom-check' +
              (cookiesState?.necessary ? '.png' : '-faded.png')
            "
          />
          <p [ngClass]="{ strong: showHelp && helpSelection === 'necessary' }">
            {{ t('necessary.title') }}
          </p>
        </div>
        <div
          fxLayout
          fxLayoutAlign="start center"
          fxLayoutGap="16px"
          class="clickable"
          (click)="handleClick('functional')"
        >
          <img
            [src]="
              '/assets/imgs/custom-check' +
              (cookiesState?.functional ? '.png' : '-faded.png')
            "
          />
          <p [ngClass]="{ strong: showHelp && helpSelection === 'functional' }">
            {{ t('functional.title') }}
          </p>
        </div>
        <div
          fxLayout
          fxLayoutAlign="start center"
          fxLayoutGap="16px"
          class="clickable"
          (click)="handleClick('performance')"
        >
          <img
            [src]="
              '/assets/imgs/custom-check' +
              (cookiesState?.performance ? '.png' : '-faded.png')
            "
          />
          <p
            [ngClass]="{ strong: showHelp && helpSelection === 'performance' }"
          >
            {{ t('performance.title') }}
          </p>
        </div>
        <div
          fxLayout
          fxLayoutAlign="start center"
          fxLayoutGap="16px"
          class="clickable"
          (click)="handleClick('personalization')"
        >
          <img
            [src]="
              '/assets/imgs/custom-check' +
              (cookiesState?.personalization ? '.png' : '-faded.png')
            "
          />
          <p
            [ngClass]="{
              strong: showHelp && helpSelection === 'personalization'
            }"
          >
            {{ t('personalization.title') }}
          </p>
        </div>
      </mat-list>

      <p *ngIf="showHelp">
        {{ t(helpSelection + '.description') }}
      </p>
    </div>
  </mat-card-content>

  <mat-card-actions
    fxLayout
    fxLayoutAlign="space-between center"
    fxLayoutGap="16px"
  >
    <button
      mat-raised-button
      color="accent"
      (click)="confirm()"
      fxFlex="1 1 auto"
    >
      {{ t('confirm') }}
    </button>
    <button mat-icon-button (click)="showHelp = !showHelp">
      <mat-icon color="accent" class="center">{{
        showHelp ? 'close' : 'help'
      }}</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
