import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { RatingFacade } from '../+state/rating.facade';

@Component({
  selector: 'rating-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingComponent {
  @Input() set value(value: number) {
    this.stars = [];
    for (let i = 0; i < 5; i++) {
      this.stars.push(i < value);
    }
  }

  @Input() module: 'quoting' | 'delivery' | 'concerning';

  stars: boolean[];

  constructor(
    private snackBar: MatSnackBar,
    private transloco: TranslocoService,
    private ratingFacade: RatingFacade
  ) {
    this.stars = [];
    this.module = 'quoting';
  }

  setRating(index: number) {
    this.ratingFacade.setRating(index + 1, this.module);
    const ratedService = this.transloco.translate(
      'rating.rating.modules.' + this.module
    );
    this.snackBar.open(
      this.transloco.translate('rating.rating.thanks', { value: ratedService }),
      this.transloco.translate('app.general.close'),
      {
        duration: 3000,
      }
    );
  }
}
