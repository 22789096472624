import { Injectable, Inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiEnvironment } from '../api/api-environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { mergeMap, take } from 'rxjs/operators';
import { ENVIRONMENT } from '@clients/helpers';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private angularFireAuth: AngularFireAuth,
    @Inject(ENVIRONMENT) private environment: ApiEnvironment
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !req.url.startsWith(this.environment.api) &&
      !req.url.startsWith(this.environment.netApi)
    ) {
      return next.handle(req);
    }

    return this.angularFireAuth.idToken.pipe(
      take(1),
      mergeMap((token: string | null) => {
        let newReq: HttpRequest<any> = req;
        newReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
        return next.handle(newReq);
      })
    );
  }
}
