import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CookiesState } from '../../+state/app.reducer';
import { AppFacade } from '../../+state/app.facade';

@Component({
  selector: 'clients-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiePolicyComponent {
  @Input() cookiesState?: CookiesState;

  constructor(private appFacade: AppFacade) {}

  showHelp = false;
  helpSelection:
    | 'necessary'
    | 'functional'
    | 'performance'
    | 'personalization' = 'necessary';

  handleClick(
    button: 'necessary' | 'functional' | 'performance' | 'personalization'
  ) {
    if (this.showHelp) {
      this.helpSelection = button;
    } else if (button === 'necessary') {
      return;
    } else {
      const tempState: CookiesState = { ...this.cookiesState } as CookiesState;
      tempState[button] = !tempState[button];
      this.appFacade.updateCookiesState(tempState);
    }
  }

  confirm() {
    this.appFacade.updateCookiesState({
      ...this.cookiesState,
      confirmed: true,
    } as CookiesState);
  }
}
