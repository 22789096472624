import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { map, tap } from 'rxjs/operators';
import { CustomerPartialState } from './customer.reducer';
import {
  CustomerActionTypes,
  LoadCustomerGroups,
  CustomerGroupsLoaded,
} from './customer.actions';
import {
  CustomerGroupControllerService,
  CustomerGroupRead,
} from '@clients/api';
import { StateErrorLogger } from '@clients/helpers';

@Injectable()
export class CustomerEffects {
  @Effect() loadCustomerGroups$ = this.dataPersistence.fetch(
    CustomerActionTypes.LoadCustomerGroups,
    {
      run: () => {
        return this.customerGroupControllerService
          .getCustomerGroupsUsingGET({})
          .pipe(
            map(
              (groups: CustomerGroupRead[]) => new CustomerGroupsLoaded(groups)
            )
          );
      },

      onError: (action: LoadCustomerGroups, error: any) => {
        StateErrorLogger.logEffectsError(action, error);
      },
    }
  );

  constructor(
    private dataPersistence: DataPersistence<CustomerPartialState>,
    private customerGroupControllerService: CustomerGroupControllerService
  ) {}
}
