import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FullscreenComponent } from './fullscreen/fullscreen.component';

@Component({
  selector: 'clients-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
  @Input() thumbnail$?: Observable<Blob>;
  @Input() fullscreen$?: Observable<Blob>;
  @Input() fallback?: string;

  constructor(private dialog: MatDialog) {}

  openDrawing() {
    if (!this.fullscreen$) {
      return;
    }

    this.dialog.open(FullscreenComponent, {
      height: '100vh',
      maxHeight: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      data: this.fullscreen$,
    });
  }
}
