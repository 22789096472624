import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { ROUTES } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@clients/shared';
import { RatingModule } from '@clients/rating';
import { ApiModule, ApiImageModule } from '@clients/api';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  StoreRouterConnectingModule,
  NavigationActionTiming,
  DefaultRouterStateSerializer,
} from '@ngrx/router-store';
import { CustomerModule } from '@clients/customer';
import { DataPersistence, NxModule } from '@nrwl/angular';
import { HttpClientModule } from '@angular/common/http';
import { translocoLoader } from './transloco.loader';
import {
  TranslocoModule,
  TRANSLOCO_CONFIG,
  TranslocoConfig,
} from '@ngneat/transloco';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SentryModule, ENVIRONMENT, logNgrx } from '@clients/helpers';
import * as fromApp from './+state/app.reducer';
import { AppEffects } from './+state/app.effects';
import { AppFacade } from './+state/app.facade';
import { VerifyEmailCardComponent } from './login/verify-email-card/verify-email-card.component';
import { PasswordResetComponent } from './login/password-reset/password-reset.component';
import { SettingsComponent } from './menu/settings/settings.component';
import { DeleteAccountDialogComponent } from './menu/delete-account-dialog/delete-account-dialog.component';
import { CookiePolicyComponent } from './login/cookie-policy/cookie-policy.component';
import { APP_CONFIG_TOKEN, STORAGE_PROVIDERS } from './app.storage';
import { INITIALIZER_PROVIDERS } from './app.initializer';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RenewSuccessComponent } from './renew-success/renew-success.component';
import { PlantSustainabilityPdfScreenComponent } from './plant-sustainability-pdf-screen/plant-sustainability-pdf-screen.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { EmissionIntensityCardComponent } from './plant-sustainability-pdf-screen/emission-intensity-card/emission-intensity-card.component';
import { EnergyConsumtionCardComponent } from './plant-sustainability-pdf-screen/energy-consumtion-card/energy-consumtion-card.component';
import { DecimalPipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    LoginComponent,
    VerifyEmailCardComponent,
    PasswordResetComponent,
    SettingsComponent,
    DeleteAccountDialogComponent,
    CookiePolicyComponent,
    RenewSuccessComponent,
    PlantSustainabilityPdfScreenComponent,
    EmissionIntensityCardComponent,
    EnergyConsumtionCardComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(ROUTES, {
      preloadingStrategy: PreloadAllModules,
    }),
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCardModule,
    MatInputModule,
    MatSnackBarModule,
    MatBottomSheetModule,
    MatDialogModule,
    NxModule.forRoot(),
    StoreModule.forRoot(
      {},
      {
        metaReducers: environment.sentry.enabled ? [logNgrx] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([AppEffects]),
    StoreModule.forFeature(
      fromApp.APP_FEATURE_KEY,
      fromApp.reducer,
      APP_CONFIG_TOKEN
    ),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot({
      navigationActionTiming: NavigationActionTiming.PostActivation,
      serializer: DefaultRouterStateSerializer,
    }),
    TranslocoModule,
    SentryModule.forRoot(environment),
    SharedModule,
    ApiModule.forRoot(environment),
    CustomerModule,
    ApiImageModule,
    RatingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enableServiceWorker,
    }),
    NgApexchartsModule,
  ],
  providers: [
    INITIALIZER_PROVIDERS,
    DataPersistence,
    AppFacade,
    DecimalPipe,
    { provide: ENVIRONMENT, useValue: environment },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        reRenderOnLangChange: true,
        defaultLang: 'en',
        fallbackLang: 'en',
        availableLangs: ['en'],
        prodMode: environment.production,
        scopeStrategy: 'shared',
      } as TranslocoConfig,
    },
    translocoLoader,
    STORAGE_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
