import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'shared-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLayoutComponent {
  @Input() headerRef?: TemplateRef<any>;
  @Input() subheaderRef?: TemplateRef<any>;
  @Input() stickyheaderRef?: TemplateRef<any>;
  @Input() contentRef?: TemplateRef<any>;
  @Input() limitContentWidth: boolean = false;
  @Input() footerRef?: TemplateRef<any>;
  @Input() loading?: boolean;
  @Input() loadingColor: 'primary' | 'accent' | 'warn' = 'primary';
}
