import {
  NgModule,
  ModuleWithProviders,
  ErrorHandler,
  Inject,
  InjectionToken,
} from '@angular/core';
import * as Sentry from '@sentry/browser';
import { SentryErrorHandler } from './error-handler';
import { SentryEnvironment } from './sentry-environment';
import { VersionEnvironment } from '../version/version-environment';
import { ENVIRONMENT } from '../environment';

export const ALLOW_SENTRY = new InjectionToken<boolean>('Environment');

@NgModule({})
export class SentryModule {
  static forRoot(
    environment: SentryEnvironment
  ): ModuleWithProviders<SentryModule> {
    return {
      ngModule: SentryModule,
      providers: environment.sentry.enabled
        ? [{ provide: ErrorHandler, useClass: SentryErrorHandler }]
        : [],
    };
  }

  constructor(
    @Inject(ENVIRONMENT)
    private environment: SentryEnvironment & VersionEnvironment,
    @Inject(ALLOW_SENTRY) private allowSentry: boolean
  ) {
    if (this.environment.sentry.enabled && this.allowSentry) {
      Sentry.init({
        dsn: this.environment.sentry.dns,
        environment: this.environment.sentry.environment,
        release: this.environment.version,
        ignoreErrors: ['A network error'],
        attachStacktrace: true,
      });
    }
  }
}
