import { Configuration } from '../auto-gen';
import { Configuration as NetConfiguration } from '../auto-gen-net';
import { ApiEnvironment } from './api-environment';

export const configurationFactory = (
  environment: ApiEnvironment
): Configuration => {
  return new Configuration({
    basePath: environment.api,
  });
};

export const netConfigurationFactory = (
  environment: ApiEnvironment
): NetConfiguration => {
  return new NetConfiguration({
    basePath: environment.netApi,
  });
};
