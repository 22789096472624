/**
 * BusinessConnect
 * BusinessConnect
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type FiltersSalutation =
  | 'Private'
  | 'Herr'
  | 'Frau'
  | 'Mr'
  | 'Ms'
  | 'Herr_Dr'
  | 'Frau_Dr'
  | 'Dr'
  | 'Prof';

export const FiltersSalutation = {
  Private: 'Private' as FiltersSalutation,
  Herr: 'Herr' as FiltersSalutation,
  Frau: 'Frau' as FiltersSalutation,
  Mr: 'Mr' as FiltersSalutation,
  Ms: 'Ms' as FiltersSalutation,
  HerrDr: 'Herr_Dr' as FiltersSalutation,
  FrauDr: 'Frau_Dr' as FiltersSalutation,
  Dr: 'Dr' as FiltersSalutation,
  Prof: 'Prof' as FiltersSalutation,
};
