<mat-list *transloco="let t; read: 'app.menu.settings'">
  <mat-list-item *ngIf="email">
    <mat-icon mat-list-icon>account_circle</mat-icon>
    {{ email }}
  </mat-list-item>
  <mat-list-item>
    <mat-icon mat-list-icon>perm_device_information</mat-icon>
    {{ environment.version }}
  </mat-list-item>
  <mat-list-item class="clickable" (click)="deleteAccount()">
    <mat-icon mat-list-icon color="warn">delete_sweep</mat-icon>
    {{ t('deleteAccount') }}
  </mat-list-item>
</mat-list>
