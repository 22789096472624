import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'clients-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullscreenComponent {
  constructor(
    public dialogRef: MatDialogRef<FullscreenComponent>,
    @Inject(MAT_DIALOG_DATA) public drawing$: Observable<Blob>
  ) {}
}
