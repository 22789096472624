import { Component } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { DeviceService } from '@clients/helpers';

@Component({
  selector: 'clients-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private deviceService: DeviceService) {
    const deviceLocale = this.deviceService.getAlpha2Language();

    function onLoadError() {
      return (err: any) => {
        console.error(`46: unable to load locale ${deviceLocale}: `, err);
      };
    }

    switch (deviceLocale) {
      case 'de':
        import(`@angular/common/locales/de.js`)
          .then((locale: any) => {
            registerLocaleData(locale.default, deviceLocale);
          })
          .catch(onLoadError());
        break;
      case 'it':
        import(`@angular/common/locales/it.js`)
          .then((locale: any) => {
            registerLocaleData(locale.default, deviceLocale);
          })
          .catch(onLoadError());
        break;
      case 'en':
      default:
        import(`@angular/common/locales/en.js`)
          .then((locale: any) => {
            registerLocaleData(locale.default, deviceLocale);
          })
          .catch(onLoadError());
    }
  }
}
