import { CustomerGroupRead } from '@clients/api';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { CustomerAction, CustomerActionTypes } from './customer.actions';
import { HelperAction, HelperActionTypes } from '@clients/helpers';

export const CUSTOMER_FEATURE_KEY = 'customer';

export interface LocationFilter {
  region_id?: number;
  country_id?: number;
}
export interface KPIFilter {
  customergroup_id: number;
  locationFilter: LocationFilter;
}
export interface LocationFilterEntityState extends EntityState<KPIFilter> {}
export const locationFilterEntityStateAdapter = createEntityAdapter<KPIFilter>({
  selectId: (filter: KPIFilter) => filter.customergroup_id,
});

export interface CustomerGroupEntityState
  extends EntityState<CustomerGroupRead> {
  loaded: boolean;
  selected?: number;
}
export const customerGroupEntityStateAdapter = createEntityAdapter<
  CustomerGroupRead
>({
  selectId: (customerGroup: CustomerGroupRead) =>
    customerGroup.customergroup_id,
});

export interface CustomerState {
  customerGroups: CustomerGroupEntityState;
  kpiFilters: LocationFilterEntityState;
}

export interface CustomerPartialState {
  readonly [CUSTOMER_FEATURE_KEY]: CustomerState;
}

export const initialState: CustomerState = {
  customerGroups: customerGroupEntityStateAdapter.getInitialState({
    loaded: false,
  }),
  kpiFilters: locationFilterEntityStateAdapter.getInitialState(),
};

export function reducer(
  state: CustomerState = initialState,
  action: CustomerAction | HelperAction
): CustomerState {
  switch (action.type) {
    case HelperActionTypes.ClearState: {
      // Duplicate of intialState; DO NOT USE initialState
      // https://github.com/ngrx/platform/issues/2032
      state = {
        customerGroups: customerGroupEntityStateAdapter.getInitialState({
          loaded: false,
        }),
        kpiFilters: locationFilterEntityStateAdapter.getInitialState(),
      };
      break;
    }
    case CustomerActionTypes.CustomerGroupsLoaded: {
      state = {
        ...state,
        customerGroups: customerGroupEntityStateAdapter.addAll(action.groups, {
          ...state.customerGroups,
          loaded: true,
          selected: selectedCustomerGroup(
            action.groups,
            state.customerGroups.selected
          ),
        }),
      };
      break;
    }
    case CustomerActionTypes.SelectCustomerGroup: {
      state = {
        ...state,
        customerGroups: {
          ...state.customerGroups,
          selected: action.customergroupId,
        },
      };
      break;
    }
    case CustomerActionTypes.SetFilter: {
      if (!state.customerGroups.selected) {
        // Not possible to set since no current customergroup is selected
        break;
      }
      state = {
        ...state,
        kpiFilters: locationFilterEntityStateAdapter.upsertOne(
          {
            customergroup_id: state.customerGroups.selected,
            locationFilter: action.filter,
          },
          state.kpiFilters
        ),
      };
      break;
    }
  }
  return state;
}

function selectedCustomerGroup(
  groups: CustomerGroupRead[],
  selected?: number
): number | undefined {
  if (groups.length === 0) {
    return undefined;
  }
  if (
    !selected ||
    !groups.find(
      (group: CustomerGroupRead) => group.customergroup_id === selected
    )
  ) {
    return groups[0].customergroup_id;
  }

  return selected;
}
