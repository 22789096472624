import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CUSTOMER_FEATURE_KEY,
  CustomerState,
  LocationFilter,
  KPIFilter,
} from './customer.reducer';
import { CustomerGroupRead } from '@clients/api';

// Lookup the 'Customer' feature state managed by NgRx
const getCustomerState = createFeatureSelector<CustomerState>(
  CUSTOMER_FEATURE_KEY
);

const getCustomerGroupsLoaded = createSelector(
  getCustomerState,
  (state: CustomerState) => state.customerGroups.loaded
);

const getAllCustomerGroups = createSelector(
  getCustomerState,
  (state: CustomerState) => {
    return (state.customerGroups.ids as number[]).map(
      (id: number) => state.customerGroups.entities[id]
    ) as CustomerGroupRead[];
  }
);
const getSelectedCustomerGroupId = createSelector(
  getCustomerState,
  (state: CustomerState): number | undefined => state.customerGroups.selected
);
const getSelectedCustomerGroup = createSelector(
  getCustomerState,
  getCustomerGroupsLoaded,
  getSelectedCustomerGroupId,
  (
    state: CustomerState,
    customerGroupsLoaded: boolean,
    id?: number
  ): CustomerGroupRead | undefined => {
    if (!customerGroupsLoaded || !id) {
      return undefined;
    }
    return state.customerGroups.entities[id] as CustomerGroupRead;
  }
);

const getSelectedLocationFilter = createSelector(
  getCustomerState,
  getSelectedCustomerGroupId,
  (state: CustomerState, selectedId?: number): LocationFilter => {
    if (!selectedId || !state.kpiFilters.entities[selectedId]) {
      return {};
    }
    return (state.kpiFilters.entities[selectedId] as KPIFilter).locationFilter;
  }
);

export const customerQuery = {
  getCustomerGroupsLoaded,
  getAllCustomerGroups,
  getSelectedCustomerGroupId,
  getSelectedCustomerGroup,
  getSelectedLocationFilter,
};
