import { Subject, OperatorFunction } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

@Component({ template: '' })
export abstract class UnsubscribeComponent implements OnDestroy {
  protected takeUntil: () => OperatorFunction<any, any>;

  private ngUnsubscribe: Subject<void>;

  constructor() {
    this.ngUnsubscribe = new Subject<void>();
    this.takeUntil = () => takeUntil(this.ngUnsubscribe);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
