import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import { Observable, EMPTY } from 'rxjs';
import { CustomerPartialState, LocationFilter } from './customer.reducer';
import { customerQuery } from './customer.selectors';
import { CustomerGroupRead } from '@clients/api';
import {
  LoadCustomerGroups,
  SelectCustomerGroup,
  SetFilter,
} from './customer.actions';
import { take, mergeMap } from 'rxjs/operators';

@Injectable()
export class CustomerFacade {
  customerGroupsLoaded$: Observable<boolean> = this.store.pipe(
    select(customerQuery.getCustomerGroupsLoaded)
  );
  allCustomerGroups$: Observable<CustomerGroupRead[]> = this.store.pipe(
    select(customerQuery.getAllCustomerGroups)
  );
  selectedCustomerId$: Observable<number | undefined> = this.store.pipe(
    select(customerQuery.getSelectedCustomerGroupId)
  );
  selectedCustomer$: Observable<
    CustomerGroupRead | undefined
  > = this.store.pipe(select(customerQuery.getSelectedCustomerGroup));
  selectedLocationFilter$: Observable<LocationFilter> = this.store.pipe(
    select(customerQuery.getSelectedLocationFilter)
  );

  constructor(private store: Store<CustomerPartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadCustomerGroups());
  }

  selectCustomer(customerGroupId: number) {
    this.store.dispatch(new SelectCustomerGroup(customerGroupId));
  }

  setLocationFilter(locationFilter: LocationFilter) {
    this.store.dispatch(new SetFilter(locationFilter));
  }

  getCustomergroupIdOrElse(
    ifFunct: (customergroupId: number) => Observable<Action>,
    elseFunct: () => Observable<Action> = () => EMPTY
  ): Observable<Action> {
    return this.selectedCustomerId$.pipe(
      take(1),
      mergeMap((customerGroupId?: number) =>
        customerGroupId ? ifFunct(customerGroupId) : elseFunct()
      )
    );
  }
}
