import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ratingQuery } from './rating.selectors';
import { Observable } from 'rxjs';
import { RatingRead } from '@clients/api';
import { SetRating, LoadRating } from './rating.actions';
import { RatingPartialState } from './rating.reducer';

@Injectable()
export class RatingFacade {
  rating$: Observable<RatingRead | undefined> = this.store.pipe(
    select(ratingQuery.getRating)
  );

  constructor(private store: Store<RatingPartialState>) {}

  setRating(value: number, module: 'quoting' | 'delivery' | 'concerning') {
    this.store.dispatch(new SetRating(value, module));
  }

  refreshRating(module: 'quoting' | 'delivery' | 'concerning') {
    this.store.dispatch(new LoadRating(module));
  }
}
