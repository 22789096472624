import { RatingRead } from '@clients/api';
import { HelperAction, HelperActionTypes } from '@clients/helpers';
import { RatingActionTypes, RatingAction } from './rating.actions';

export const RATING_FEATURE_KEY = 'rating';

export interface RatingState {
  rating?: RatingRead;
}

export interface RatingPartialState {
  readonly [RATING_FEATURE_KEY]: RatingState;
}

export const initialState: RatingState = {};

export function reducer(
  state: RatingState = initialState,
  action: RatingAction | HelperAction
): RatingState {
  switch (action.type) {
    case HelperActionTypes.ClearState: {
      // Duplicate of intialState; DO NOT USE initialState
      // https://github.com/ngrx/platform/issues/2032
      state = {
        rating: undefined,
      };
      break;
    }
    case RatingActionTypes.RatingLoaded:
    case RatingActionTypes.RatingSet: {
      state = {
        ...state,
        rating: action.rating,
      };
      break;
    }
    case RatingActionTypes.LoadRating: {
      state = {
        ...state,
        rating: state.rating,
      };
      break;
    }
  }
  return state;
}
