/**
 * BusinessConnect
 * BusinessConnect
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { SupplierReadModel } from '../model/supplierReadModel';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class SupplierService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * MinLevel:Member
   * @param customergroupId
   * @param Authorization
   * @param Device_Token
   * @param Client_Version
   * @param Firebase_Regtoken
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSuppliers(
    params: {
      customergroupId: number;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<SupplierReadModel>>;
  public getSuppliers(
    params: {
      customergroupId: number;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<SupplierReadModel>>>;
  public getSuppliers(
    params: {
      customergroupId: number;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<SupplierReadModel>>>;
  public getSuppliers(
    params: {
      customergroupId: number;
      Authorization?: string;
      Device_Token?: string;
      Client_Version?: string;
      Firebase_Regtoken?: string;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroupId: number = params.customergroupId;
    const Authorization: string | undefined = params.Authorization;
    const Device_Token: string | undefined = params.Device_Token;
    const Client_Version: string | undefined = params.Client_Version;
    const Firebase_Regtoken: string | undefined = params.Firebase_Regtoken;

    if (customergroupId === null || customergroupId === undefined) {
      throw new Error(
        'Required parameter customergroupId was null or undefined when calling getSuppliers.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }
    if (Device_Token !== undefined && Device_Token !== null) {
      headers = headers.set('Device-Token', String(Device_Token));
    }
    if (Client_Version !== undefined && Client_Version !== null) {
      headers = headers.set('Client-Version', String(Client_Version));
    }
    if (Firebase_Regtoken !== undefined && Firebase_Regtoken !== null) {
      headers = headers.set('Firebase-Regtoken', String(Firebase_Regtoken));
    }

    // authentication (Bearer) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Authorization']
    ) {
      headers = headers.set(
        'Authorization',
        this.configuration.apiKeys['Authorization']
      );
    }

    // authentication (Device-Token) required
    if (
      this.configuration.apiKeys &&
      this.configuration.apiKeys['Device-Token']
    ) {
      headers = headers.set(
        'Device-Token',
        this.configuration.apiKeys['Device-Token']
      );
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
      'application/xml',
      'text/xml',
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<Array<SupplierReadModel>>(
      `${
        this.configuration.basePath
      }/api/v1.0/Supplier/customergroup/${encodeURIComponent(
        String(customergroupId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
