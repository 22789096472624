import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ApiImagePipe } from './image.pipe';
import { ImageComponent } from './image.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiImageInterceptor } from './image.interceptor';
import { FullscreenComponent } from './fullscreen/fullscreen.component';

@NgModule({
  declarations: [ApiImagePipe, ImageComponent, FullscreenComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
  ],
  exports: [ImageComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiImageInterceptor, multi: true },
  ],
})
export class ApiImageModule {}
