import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  open(uri: string, params?: { [key: string]: any }) {
    let paramsSuffix = '';
    if (params) {
      paramsSuffix += '?';
      Object.keys(params).forEach((key: string) => {
        paramsSuffix += `${key}=${params[key]}&`;
      });
    }
    this.document.location.href = encodeURI(`${uri}${paramsSuffix}`);
  }

  reload() {
    this.document.location.reload();
  }

  getOrigin(): string {
    return this.document.location.origin;
  }
}
