<ng-container *transloco="let t; read: 'app'">
  <shared-page-layout
    [headerRef]="headerRef"
    [subheaderRef]="subheaderRef"
    [stickyheaderRef]="stickyheaderRef"
    [contentRef]="contentRef"
    [footerRef]="footerRef"
    [loading]="!(customersLoaded$ | async)"
    [loadingColor]="'accent'"
  >
  </shared-page-layout>

  <ng-template #headerRef>
    <mat-toolbar class="transparent"></mat-toolbar>
  </ng-template>

  <ng-template #subheaderRef>
    <div class="sub-header"></div>
  </ng-template>

  <ng-template #stickyheaderRef>
    <mat-toolbar color="accent" fxLayoutAlign="center center" class="title">
      {{ t('title') }}
    </mat-toolbar>
  </ng-template>

  <ng-template #contentRef>
    <div class="content">
      <ng-container *ngIf="allCustomers?.length > 0">
        <mat-toolbar class="logo">
          <clients-image
            class="image"
            [thumbnail$]="customerIcon$"
            [fallback]="selectedCustomer?.name"
          ></clients-image>
        </mat-toolbar>
        <mat-list>
          <mat-list-item>
            <mat-form-field
              class="full-width no-line no-value"
              [ngClass]="{ 'no-arrow': allCustomers?.length < 2 }"
            >
              <mat-select
                [disabled]="allCustomers?.length < 2"
                (selectionChange)="selectCustomer($event)"
              >
                <mat-option
                  *ngFor="let customer of allCustomers"
                  [value]="customer.customergroup_id"
                >
                  {{ customer.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-list-item>
        </mat-list>

        <mat-divider></mat-divider>

        <mat-nav-list>
          <mat-list-item
            [routerLink]="['/quotes', selectedCustomer?.customergroup_id]"
          >
            <mat-icon mat-list-icon></mat-icon>
            <a matLine>{{ t('kpis.quotes') }}</a>
            <button mat-icon-button>
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item
            [routerLink]="['/deliveries', selectedCustomer?.customergroup_id]"
          >
            <mat-icon mat-list-icon></mat-icon>
            <a matLine>{{ t('kpis.deliveries') }}</a>
            <button mat-icon-button>
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item
            [routerLink]="['/concerns', selectedCustomer?.customergroup_id]"
          >
            <mat-icon mat-list-icon></mat-icon>
            <a matLine>{{ t('kpis.concerns') }}</a>
            <button mat-icon-button>
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item
            [routerLink]="[
              '/sustainability',
              selectedCustomer?.customergroup_id
            ]"
          >
            <mat-icon mat-list-icon></mat-icon>
            <a matLine>{{ t('kpis.sustainability') }}</a>
            <button mat-icon-button>
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </mat-list-item>
          <mat-divider></mat-divider>
        </mat-nav-list>
      </ng-container>

      <mat-nav-list>
        <mat-list-item [routerLink]="['/filters']">
          <mat-icon mat-list-icon></mat-icon>
          <a matLine>{{ t('kpis.filters') }}</a>
          <button mat-icon-button>
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-nav-list>
    </div>
  </ng-template>

  <ng-template #footerRef>
    <mat-nav-list class="bottom-list">
      <mat-list-item (click)="openSettings()">
        <mat-icon mat-list-icon>settings</mat-icon>
        <a matLine>{{ t('menu.settings.title') }}</a>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item (click)="logout()">
        <mat-icon mat-list-icon>exit_to_app</mat-icon>
        <a matLine>{{ t('menu.logout') }}</a>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-nav-list>
  </ng-template>
</ng-container>
