/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { DeliveryStockContainer } from '../model/deliveryStockContainer';
import { DeliveryStockOverview } from '../model/deliveryStockOverview';
import { DeliveryStockRead } from '../model/deliveryStockRead';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class DeliveryStockControllerService {
  protected basePath =
    'http://api.bc.gknpm.fortysix.world/business-connect-api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * getDeliveryStockOverview
   * @param customergroup_id customergroup_id
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeliveryStockOverviewUsingGET(
    params: { customergroup_id: number; Authorization?: string },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DeliveryStockOverview>;
  public getDeliveryStockOverviewUsingGET(
    params: { customergroup_id: number; Authorization?: string },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DeliveryStockOverview>>;
  public getDeliveryStockOverviewUsingGET(
    params: { customergroup_id: number; Authorization?: string },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DeliveryStockOverview>>;
  public getDeliveryStockOverviewUsingGET(
    params: { customergroup_id: number; Authorization?: string },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroup_id: number = params.customergroup_id;
    const Authorization: string | undefined = params.Authorization;

    if (customergroup_id === null || customergroup_id === undefined) {
      throw new Error(
        'Required parameter customergroup_id was null or undefined when calling getDeliveryStockOverviewUsingGET.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<DeliveryStockOverview>(
      `${this.configuration.basePath}/customer-group/${encodeURIComponent(
        String(customergroup_id)
      )}/deliverystock/overview`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * getDeliveryStocks
   * @param customergroup_id customergroup_id
   * @param Authorization
   * @param customerpartnumber customerpartnumber
   * @param site site
   * @param site_key site_key
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeliveryStocksUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      customerpartnumber?: string;
      site?: string;
      site_key?: string;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<DeliveryStockRead>>;
  public getDeliveryStocksUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      customerpartnumber?: string;
      site?: string;
      site_key?: string;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<DeliveryStockRead>>>;
  public getDeliveryStocksUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      customerpartnumber?: string;
      site?: string;
      site_key?: string;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<DeliveryStockRead>>>;
  public getDeliveryStocksUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      customerpartnumber?: string;
      site?: string;
      site_key?: string;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroup_id: number = params.customergroup_id;
    const Authorization: string | undefined = params.Authorization;
    const customerpartnumber: string | undefined = params.customerpartnumber;
    const site: string | undefined = params.site;
    const site_key: string | undefined = params.site_key;

    if (customergroup_id === null || customergroup_id === undefined) {
      throw new Error(
        'Required parameter customergroup_id was null or undefined when calling getDeliveryStocksUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (customerpartnumber !== undefined && customerpartnumber !== null) {
      queryParameters = queryParameters.set(
        'customerpartnumber',
        <any>customerpartnumber
      );
    }
    if (site !== undefined && site !== null) {
      queryParameters = queryParameters.set('site', <any>site);
    }
    if (site_key !== undefined && site_key !== null) {
      queryParameters = queryParameters.set('site_key', <any>site_key);
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<Array<DeliveryStockRead>>(
      `${this.configuration.basePath}/customer-group/${encodeURIComponent(
        String(customergroup_id)
      )}/deliverystock`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * updateAllDeliveryStocks
   * @param delivery_stocks delivery_stocks
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAllDeliveryStocksUsingPUT(
    params: { delivery_stocks: DeliveryStockContainer; Authorization?: string },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateAllDeliveryStocksUsingPUT(
    params: { delivery_stocks: DeliveryStockContainer; Authorization?: string },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateAllDeliveryStocksUsingPUT(
    params: { delivery_stocks: DeliveryStockContainer; Authorization?: string },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateAllDeliveryStocksUsingPUT(
    params: { delivery_stocks: DeliveryStockContainer; Authorization?: string },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const delivery_stocks: DeliveryStockContainer = params.delivery_stocks;
    const Authorization: string | undefined = params.Authorization;

    if (delivery_stocks === null || delivery_stocks === undefined) {
      throw new Error(
        'Required parameter delivery_stocks was null or undefined when calling updateAllDeliveryStocksUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/deliverystock/all`,
      delivery_stocks,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * updateDeliveryStocksOfCustomergroup
   * @param customergroup_key customergroup_key
   * @param delivery_stocks delivery_stocks
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateDeliveryStocksOfCustomergroupUsingPUT(
    params: {
      customergroup_key: string;
      delivery_stocks: DeliveryStockContainer;
      Authorization?: string;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateDeliveryStocksOfCustomergroupUsingPUT(
    params: {
      customergroup_key: string;
      delivery_stocks: DeliveryStockContainer;
      Authorization?: string;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateDeliveryStocksOfCustomergroupUsingPUT(
    params: {
      customergroup_key: string;
      delivery_stocks: DeliveryStockContainer;
      Authorization?: string;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateDeliveryStocksOfCustomergroupUsingPUT(
    params: {
      customergroup_key: string;
      delivery_stocks: DeliveryStockContainer;
      Authorization?: string;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroup_key: string = params.customergroup_key;
    const delivery_stocks: DeliveryStockContainer = params.delivery_stocks;
    const Authorization: string | undefined = params.Authorization;

    if (customergroup_key === null || customergroup_key === undefined) {
      throw new Error(
        'Required parameter customergroup_key was null or undefined when calling updateDeliveryStocksOfCustomergroupUsingPUT.'
      );
    }
    if (delivery_stocks === null || delivery_stocks === undefined) {
      throw new Error(
        'Required parameter delivery_stocks was null or undefined when calling updateDeliveryStocksOfCustomergroupUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${
        this.configuration.basePath
      }/deliverystock/customergroup/${encodeURIComponent(
        String(customergroup_key)
      )}`,
      delivery_stocks,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * updateDeliveryStocksOfPart
   * @param customerpartnumber customerpartnumber
   * @param delivery_stocks delivery_stocks
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateDeliveryStocksOfPartUsingPUT(
    params: {
      customerpartnumber: string;
      delivery_stocks: DeliveryStockContainer;
      Authorization?: string;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateDeliveryStocksOfPartUsingPUT(
    params: {
      customerpartnumber: string;
      delivery_stocks: DeliveryStockContainer;
      Authorization?: string;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateDeliveryStocksOfPartUsingPUT(
    params: {
      customerpartnumber: string;
      delivery_stocks: DeliveryStockContainer;
      Authorization?: string;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateDeliveryStocksOfPartUsingPUT(
    params: {
      customerpartnumber: string;
      delivery_stocks: DeliveryStockContainer;
      Authorization?: string;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customerpartnumber: string = params.customerpartnumber;
    const delivery_stocks: DeliveryStockContainer = params.delivery_stocks;
    const Authorization: string | undefined = params.Authorization;

    if (customerpartnumber === null || customerpartnumber === undefined) {
      throw new Error(
        'Required parameter customerpartnumber was null or undefined when calling updateDeliveryStocksOfPartUsingPUT.'
      );
    }
    if (delivery_stocks === null || delivery_stocks === undefined) {
      throw new Error(
        'Required parameter delivery_stocks was null or undefined when calling updateDeliveryStocksOfPartUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/deliverystock/part/${encodeURIComponent(
        String(customerpartnumber)
      )}`,
      delivery_stocks,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * updateDeliveryStocksOfSite
   * @param site_key site_key
   * @param delivery_stocks delivery_stocks
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateDeliveryStocksOfSiteUsingPUT(
    params: {
      site_key: string;
      delivery_stocks: DeliveryStockContainer;
      Authorization?: string;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateDeliveryStocksOfSiteUsingPUT(
    params: {
      site_key: string;
      delivery_stocks: DeliveryStockContainer;
      Authorization?: string;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateDeliveryStocksOfSiteUsingPUT(
    params: {
      site_key: string;
      delivery_stocks: DeliveryStockContainer;
      Authorization?: string;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateDeliveryStocksOfSiteUsingPUT(
    params: {
      site_key: string;
      delivery_stocks: DeliveryStockContainer;
      Authorization?: string;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const site_key: string = params.site_key;
    const delivery_stocks: DeliveryStockContainer = params.delivery_stocks;
    const Authorization: string | undefined = params.Authorization;

    if (site_key === null || site_key === undefined) {
      throw new Error(
        'Required parameter site_key was null or undefined when calling updateDeliveryStocksOfSiteUsingPUT.'
      );
    }
    if (delivery_stocks === null || delivery_stocks === undefined) {
      throw new Error(
        'Required parameter delivery_stocks was null or undefined when calling updateDeliveryStocksOfSiteUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/deliverystock/site/${encodeURIComponent(
        String(site_key)
      )}`,
      delivery_stocks,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
