/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { DeviceInfo } from '../model/deviceInfo';
import { WhoAmI } from '../model/whoAmI';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class LoginControllerService {
  protected basePath =
    'http://api.bc.gknpm.fortysix.world/business-connect-api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * deleteMyAccount
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteMyAccountUsingDELETE(
    params: { Authorization?: string },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteMyAccountUsingDELETE(
    params: { Authorization?: string },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteMyAccountUsingDELETE(
    params: { Authorization?: string },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteMyAccountUsingDELETE(
    params: { Authorization?: string },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const Authorization: string | undefined = params.Authorization;

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/myaccount`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * login
   * @param deviceInfo deviceInfo
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loginUsingPOST(
    params: { deviceInfo: DeviceInfo; Authorization?: string },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<WhoAmI>;
  public loginUsingPOST(
    params: { deviceInfo: DeviceInfo; Authorization?: string },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<WhoAmI>>;
  public loginUsingPOST(
    params: { deviceInfo: DeviceInfo; Authorization?: string },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<WhoAmI>>;
  public loginUsingPOST(
    params: { deviceInfo: DeviceInfo; Authorization?: string },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const deviceInfo: DeviceInfo = params.deviceInfo;
    const Authorization: string | undefined = params.Authorization;

    if (deviceInfo === null || deviceInfo === undefined) {
      throw new Error(
        'Required parameter deviceInfo was null or undefined when calling loginUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<WhoAmI>(
      `${this.configuration.basePath}/login`,
      deviceInfo,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * logout
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public logoutUsingPOST(
    params: { Authorization?: string },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public logoutUsingPOST(
    params: { Authorization?: string },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public logoutUsingPOST(
    params: { Authorization?: string },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public logoutUsingPOST(
    params: { Authorization?: string },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const Authorization: string | undefined = params.Authorization;

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.post<any>(
      `${this.configuration.basePath}/logout`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
