/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { ProjectContainer } from '../model/projectContainer';
import { ProjectListRead } from '../model/projectListRead';
import { ProjectRead } from '../model/projectRead';
import { ProjectTrend } from '../model/projectTrend';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class ProjectControllerService {
  protected basePath =
    'http://api.bc.gknpm.fortysix.world/business-connect-api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * deleteProjects
   * @param erfq_nos erfq_nos
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteProjectsUsingDELETE(
    params: { erfq_nos: Array<string>; Authorization?: string },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteProjectsUsingDELETE(
    params: { erfq_nos: Array<string>; Authorization?: string },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteProjectsUsingDELETE(
    params: { erfq_nos: Array<string>; Authorization?: string },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteProjectsUsingDELETE(
    params: { erfq_nos: Array<string>; Authorization?: string },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const erfq_nos: Array<string> = params.erfq_nos;
    const Authorization: string | undefined = params.Authorization;

    if (erfq_nos === null || erfq_nos === undefined) {
      throw new Error(
        'Required parameter erfq_nos was null or undefined when calling deleteProjectsUsingDELETE.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (erfq_nos) {
      erfq_nos.forEach((element) => {
        queryParameters = queryParameters.append('erfq_nos', <any>element);
      });
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/project`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * getProjectDetail
   * @param customergroup_id customergroup_id
   * @param project_id project_id
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProjectDetailUsingGET(
    params: {
      customergroup_id: number;
      project_id: number;
      Authorization?: string;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ProjectRead>;
  public getProjectDetailUsingGET(
    params: {
      customergroup_id: number;
      project_id: number;
      Authorization?: string;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ProjectRead>>;
  public getProjectDetailUsingGET(
    params: {
      customergroup_id: number;
      project_id: number;
      Authorization?: string;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ProjectRead>>;
  public getProjectDetailUsingGET(
    params: {
      customergroup_id: number;
      project_id: number;
      Authorization?: string;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroup_id: number = params.customergroup_id;
    const project_id: number = params.project_id;
    const Authorization: string | undefined = params.Authorization;

    if (customergroup_id === null || customergroup_id === undefined) {
      throw new Error(
        'Required parameter customergroup_id was null or undefined when calling getProjectDetailUsingGET.'
      );
    }
    if (project_id === null || project_id === undefined) {
      throw new Error(
        'Required parameter project_id was null or undefined when calling getProjectDetailUsingGET.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<ProjectRead>(
      `${this.configuration.basePath}/customer-group/${encodeURIComponent(
        String(customergroup_id)
      )}/project/${encodeURIComponent(String(project_id))}/details`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * getProjectsList
   * @param customergroup_id customergroup_id
   * @param Authorization
   * @param closed_since closed_since
   * @param country_id country_id
   * @param customer_id customer_id
   * @param region_id region_id
   * @param state state
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProjectsListUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      closed_since?: Date;
      country_id?: number;
      customer_id?: number;
      region_id?: number;
      state?: 'open' | 'closed' | 'canceled';
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<ProjectListRead>>;
  public getProjectsListUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      closed_since?: Date;
      country_id?: number;
      customer_id?: number;
      region_id?: number;
      state?: 'open' | 'closed' | 'canceled';
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ProjectListRead>>>;
  public getProjectsListUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      closed_since?: Date;
      country_id?: number;
      customer_id?: number;
      region_id?: number;
      state?: 'open' | 'closed' | 'canceled';
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ProjectListRead>>>;
  public getProjectsListUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      closed_since?: Date;
      country_id?: number;
      customer_id?: number;
      region_id?: number;
      state?: 'open' | 'closed' | 'canceled';
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroup_id: number = params.customergroup_id;
    const Authorization: string | undefined = params.Authorization;
    const closed_since: Date | undefined = params.closed_since;
    const country_id: number | undefined = params.country_id;
    const customer_id: number | undefined = params.customer_id;
    const region_id: number | undefined = params.region_id;
    const state: 'open' | 'closed' | 'canceled' | undefined = params.state;

    if (customergroup_id === null || customergroup_id === undefined) {
      throw new Error(
        'Required parameter customergroup_id was null or undefined when calling getProjectsListUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (closed_since !== undefined && closed_since !== null) {
      queryParameters = queryParameters.set(
        'closed_since',
        <any>closed_since.toISOString()
      );
    }
    if (country_id !== undefined && country_id !== null) {
      queryParameters = queryParameters.set('country_id', <any>country_id);
    }
    if (customer_id !== undefined && customer_id !== null) {
      queryParameters = queryParameters.set('customer_id', <any>customer_id);
    }
    if (region_id !== undefined && region_id !== null) {
      queryParameters = queryParameters.set('region_id', <any>region_id);
    }
    if (state !== undefined && state !== null) {
      queryParameters = queryParameters.set('state', <any>state);
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<Array<ProjectListRead>>(
      `${this.configuration.basePath}/customer-group/${encodeURIComponent(
        String(customergroup_id)
      )}/project`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * getTrend
   * @param customergroup_id customergroup_id
   * @param Authorization
   * @param country_id country_id
   * @param customer_id customer_id
   * @param region_id region_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTrendUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      country_id?: number;
      customer_id?: number;
      region_id?: number;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ProjectTrend>;
  public getTrendUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      country_id?: number;
      customer_id?: number;
      region_id?: number;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ProjectTrend>>;
  public getTrendUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      country_id?: number;
      customer_id?: number;
      region_id?: number;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ProjectTrend>>;
  public getTrendUsingGET(
    params: {
      customergroup_id: number;
      Authorization?: string;
      country_id?: number;
      customer_id?: number;
      region_id?: number;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroup_id: number = params.customergroup_id;
    const Authorization: string | undefined = params.Authorization;
    const country_id: number | undefined = params.country_id;
    const customer_id: number | undefined = params.customer_id;
    const region_id: number | undefined = params.region_id;

    if (customergroup_id === null || customergroup_id === undefined) {
      throw new Error(
        'Required parameter customergroup_id was null or undefined when calling getTrendUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (country_id !== undefined && country_id !== null) {
      queryParameters = queryParameters.set('country_id', <any>country_id);
    }
    if (customer_id !== undefined && customer_id !== null) {
      queryParameters = queryParameters.set('customer_id', <any>customer_id);
    }
    if (region_id !== undefined && region_id !== null) {
      queryParameters = queryParameters.set('region_id', <any>region_id);
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<ProjectTrend>(
      `${this.configuration.basePath}/customer-group/${encodeURIComponent(
        String(customergroup_id)
      )}/projects/trend`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * updateProjects
   * @param projects projects
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProjectsUsingPUT(
    params: { projects: ProjectContainer; Authorization?: string },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public updateProjectsUsingPUT(
    params: { projects: ProjectContainer; Authorization?: string },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public updateProjectsUsingPUT(
    params: { projects: ProjectContainer; Authorization?: string },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public updateProjectsUsingPUT(
    params: { projects: ProjectContainer; Authorization?: string },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const projects: ProjectContainer = params.projects;
    const Authorization: string | undefined = params.Authorization;

    if (projects === null || projects === undefined) {
      throw new Error(
        'Required parameter projects was null or undefined when calling updateProjectsUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/project`,
      projects,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
