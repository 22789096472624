import { ApiEnvironment, FirebaseEnvironment } from '@clients/api';
import { version } from './version';
import { VersionEnvironment, SentryEnvironment } from '@clients/helpers';

export const environment: VersionEnvironment &
  ApiEnvironment &
  FirebaseEnvironment &
  SentryEnvironment & { production: boolean; enableServiceWorker: boolean } = {
  production: true,
  enableServiceWorker: true,
  version,
  api: 'https://businessconnectapi.gknpm.com',
  netApi: 'https://businessconnectapi.gknpm.com/apinet',
  firebase: {
    apiKey: 'AIzaSyDW5ViMPzGh883GPcPHHYuijsWF56j6KIU',
    authDomain: 'business-connect-7fe9a.firebaseapp.com',
    databaseURL: 'https://business-connect-7fe9a.firebaseio.com',
    projectId: 'business-connect-7fe9a',
    storageBucket: '',
    messagingSenderId: '84785025862',
    appId: '1:84785025862:web:23487e9c20d57f9cd04b47',
  },
  sentry: {
    dns: 'https://427ef036d0be4050874eb88738bd4479@sentry.fortysix.world/11',
    environment: 'prod',
    enabled: true,
  },
};
