import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import {ENVIRONMENT, UnsubscribeComponent} from '@clients/helpers';
import {
  ApiEnvironment,
  PlantSustainabilityExportModel,
} from '@clients/api';
import {HttpClient, HttpParameterCodec, HttpParams} from "@angular/common/http";
import {Configuration} from "../../../../../libs/api/src/lib/auto-gen-net";
import {CustomHttpParameterCodec} from "../../../../../libs/api/src/lib/auto-gen-net/encoder";

@Component({
  selector: 'plant-sustainability-pdf-screen',
  templateUrl: './plant-sustainability-pdf-screen.component.html',
  styleUrls: ['./plant-sustainability-pdf-screen.component.scss'],
})
export class PlantSustainabilityPdfScreenComponent
  extends UnsubscribeComponent
  implements OnInit {
  apiToken?: string;
  siteId?: string;
  customerGroupId?: string;
  noLocalHost: boolean = false;

  public encoder: HttpParameterCodec;

  plantSustainabilityData?: PlantSustainabilityExportModel;
  date: Date = new Date();

  constructor(
    private activatedRoute: ActivatedRoute,
    protected httpClient: HttpClient,
    @Inject(ENVIRONMENT) private environment: ApiEnvironment,
    @Optional() configuration: Configuration
  ) {
    super();
    this.encoder = configuration.encoder || new CustomHttpParameterCodec();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(this.takeUntil(), take(1))
      .subscribe((params) => {
        this.apiToken = params.token;
        this.siteId = params.siteId;
        this.customerGroupId = params.customergroupId;
        this.noLocalHost = params.noLocalHost;
      });

    if (
      this.siteId !== undefined &&
      this.apiToken !== undefined &&
      this.customerGroupId !== undefined
    ) {
      let queryParameters = new HttpParams({ encoder: this.encoder });
      if (this.siteId !== undefined && this.siteId !== null) {
        queryParameters = queryParameters.set('siteId', <any>this.siteId);
      }
      if (this.apiToken !== undefined && this.apiToken !== null) {
        queryParameters = queryParameters.set('token', <any>this.apiToken);
      }

      let basePath = "http://localhost";
      if(this.noLocalHost){
        basePath = this.environment.netApi
      }

      this.httpClient.get<PlantSustainabilityExportModel>(
        `${basePath}/api/v1.0/customer-group/${encodeURIComponent(
          String(this.customerGroupId)
        )}/PlantSustainabilityExport/export-data`,
        {
          params: queryParameters,
          observe: 'body',
          reportProgress: false,
        }
      ).pipe(this.takeUntil())
        .subscribe((data?: PlantSustainabilityExportModel) => {
          this.plantSustainabilityData = data;
        });
    }
  }
}
