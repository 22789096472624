import { AppAction, AppActionTypes } from './app.actions';
import { HelperAction, HelperActionTypes } from '@clients/helpers';

export const APP_FEATURE_KEY = 'app';

export interface CookiesState {
  confirmed: boolean;
  necessary: boolean;
  functional: boolean;
  performance: boolean;
  personalization: boolean;
}

export interface AppState {
  loaded: boolean;
  userEmail?: string;
  cookiesState: CookiesState;
}

export interface AppPartialState {
  readonly [APP_FEATURE_KEY]: AppState;
}

export const initialState: AppState = {
  loaded: false,
  cookiesState: {
    confirmed: false,
    necessary: true,
    functional: true,
    performance: true,
    personalization: true,
  },
};

export function reducer(
  state: AppState = initialState,
  action: AppAction | HelperAction
): AppState {
  switch (action.type) {
    case HelperActionTypes.ClearState: {
      // Called on logout
      state = {
        ...state,
        userEmail: undefined,
      };
      break;
    }
    case AppActionTypes.AppLoaded: {
      state = {
        ...state,
        loaded: true,
      };
      break;
    }
    case AppActionTypes.SetEmail: {
      state = {
        ...state,
        userEmail: action.email,
      };
      break;
    }
    case AppActionTypes.UpdateCookiesState: {
      state = {
        ...state,
        cookiesState: action.cookiesState,
      };
      break;
    }
  }
  return state;
}
