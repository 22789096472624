import { Pipe, PipeTransform } from '@angular/core';
import { Observable, Observer, of, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';

@Pipe({
  name: 'apiImage',
  pure: true,
})
export class ApiImagePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(
    observable: Observable<Blob> = throwError('')
  ): Observable<{ styleUrl: SafeStyle; srcUrl: SafeUrl } | null> {
    return observable.pipe(
      mergeMap((blob: Blob) => {
        return new Observable<{ styleUrl: SafeStyle; srcUrl: SafeUrl }>(
          (observer: Observer<{ styleUrl: SafeStyle; srcUrl: SafeUrl }>) => {
            const reader: FileReader = new FileReader();
            reader.onloadend = () => {
              observer.next({
                styleUrl: this.domSanitizer.bypassSecurityTrustStyle(
                  `url(${reader.result})`
                ),
                srcUrl: this.domSanitizer.bypassSecurityTrustUrl(
                  reader.result as string
                ),
              });
              observer.complete();
            };
            reader.onerror = (err: any) => observer.error(err);
            reader.readAsDataURL(blob);
          }
        );
      }),
      catchError(() => {
        // To display broken image icon of browser
        return of(null);
      })
    );
  }
}
