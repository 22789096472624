import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivateChild,
  CanActivate,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UpdateService } from './update.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UpdateGuard implements CanActivate, CanActivateChild {
  constructor(private updateService: UpdateService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.updateIfAvailable(next, state);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.updateIfAvailable(next, state);
  }

  private updateIfAvailable(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.updateService
      .updateIfAvailable(state.url)
      .pipe(map((updateAvailable: boolean) => !updateAvailable));
  }
}
