import { Action } from '@ngrx/store';
import { CookiesState } from './app.reducer';

export enum AppActionTypes {
  StartAppInitializer = '[App] Start App Initializer',
  FinishAppInitializer = '[App] Finish App Initializer',
  LoadApp = '[App] Load App',
  AppLoaded = '[App] App Loaded',
  SetEmail = '[App] Set Email',
  UpdateCookiesState = '[App] Update Cookies State',
}

export class StartAppInitializer implements Action {
  readonly type = AppActionTypes.StartAppInitializer;
}

export class FinishAppInitializer implements Action {
  readonly type = AppActionTypes.FinishAppInitializer;
}

export class LoadApp implements Action {
  readonly type = AppActionTypes.LoadApp;
}

export class AppLoaded implements Action {
  readonly type = AppActionTypes.AppLoaded;
}

export class SetEmail implements Action {
  readonly type = AppActionTypes.SetEmail;
  constructor(public email?: string) {}
}

export class UpdateCookiesState implements Action {
  readonly type = AppActionTypes.UpdateCookiesState;
  constructor(public cookiesState: CookiesState) {}
}

export type AppAction =
  | LoadApp
  | AppLoaded
  | SetEmail
  | StartAppInitializer
  | FinishAppInitializer
  | UpdateCookiesState;

export const fromAppActions = {
  StartAppInitializer,
  FinishAppInitializer,
  LoadApp,
  AppLoaded,
  SetEmail,
  UpdateCookiesState,
};
