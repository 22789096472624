import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { UpdateService } from '../version/update/update.service';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private updateService: UpdateService) {}

  handleError(error: any) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    console.error(error.originalError || error);
    Sentry.addBreadcrumb({ category: 'handleError', data: error });
    if (
      error.message
        .toString()
        .startsWith('Uncaught (in promise): ChunkLoadError') ||
      chunkFailedMessage.test(error.message)
    ) {
      console.log('forcing update');
      Sentry.addBreadcrumb({
        category: 'handleError',
        data: error,
        message: `ChunkLoadError recognised; Forcing update`,
      });
      this.updateService.forceUpdate();
      return;
    }
    const eventId = Sentry.captureException(
      error.originalError || error.error || error.message || error
    );
    // Sentry.showReportDialog({ eventId });
  }
}
