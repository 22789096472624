import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  Optional,
} from '@angular/core';
import { ENVIRONMENT, VersionEnvironment } from '@clients/helpers';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteAccountDialogComponent } from '../delete-account-dialog/delete-account-dialog.component';

@Component({
  selector: 'clients-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent {
  constructor(
    private bottomsheetRef: MatBottomSheetRef<SettingsComponent>,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public email: string,
    private dialog: MatDialog,
    @Inject(ENVIRONMENT) public environment: VersionEnvironment
  ) {}

  deleteAccount() {
    const dialog: MatDialogRef<DeleteAccountDialogComponent> = this.dialog.open(
      DeleteAccountDialogComponent,
      { closeOnNavigation: true }
    );
    dialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.bottomsheetRef.dismiss();
      }
    });
  }
}
