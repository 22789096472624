import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'renew-success',
  templateUrl: './renew-success.component.html',
  styleUrls: ['./renew-success.component.scss'],
})
export class RenewSuccessComponent {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackbar: MatSnackBar,
    private transloco: TranslocoService
  ) {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      let status: string | null = null;

      if (params.hasOwnProperty('state')) {
        status = params['state'];
      }

      //https://bc.gknpm.fortysix.world/renewal?state=invalid
      //https://bc.gknpm.fortysix.world/renewal?state=expired
      //https://bc.gknpm.fortysix.world/renewal?state=successful

      if (status != null) {
        if (status == 'successful') {
          this.transloco
            .selectTranslate(['app.renew.success', 'app.general.close'])
            .pipe(take(1))
            .subscribe((message: string[]) => {
              this.snackbar.open(message[0], message[1], {
                duration: 5000,
              });
            });
        } else if (status == 'expired') {
          this.transloco
            .selectTranslate(['app.renew.expired', 'app.general.close'])
            .pipe(take(1))
            .subscribe((message: string[]) => {
              this.snackbar.open(message[0], message[1], {
                duration: 5000,
                panelClass: 'error-snack',
              });
            });
        } else {
          this.transloco
            .selectTranslate(['app.renew.invalid', 'app.general.close'])
            .pipe(take(1))
            .subscribe((message: string[]) => {
              this.snackbar.open(message[0], message[1], {
                duration: 5000,
                panelClass: 'error-snack',
              });
            });
        }
      }

      this.router.navigate(['/']);
    });
  }
}
