/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DeliveryStatOverview {
  amount: number;
  amount_on_time: number;
  on_time_percentage?: number;
  trend_performance?: DeliveryStatOverview.TrendPerformanceEnum;
}
export namespace DeliveryStatOverview {
  export type TrendPerformanceEnum = 'rising' | 'falling' | 'consistent';
  export const TrendPerformanceEnum = {
    Rising: 'rising' as TrendPerformanceEnum,
    Falling: 'falling' as TrendPerformanceEnum,
    Consistent: 'consistent' as TrendPerformanceEnum,
  };
}
