/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DetailedStepPrediction } from './detailedStepPrediction';
import { DetailedStepChange } from './detailedStepChange';
import { ContactRead } from './contactRead';

export interface ProjectRead {
  contact?: ContactRead;
  contact_key?: string;
  contains_drawing: boolean;
  customer_no: string;
  customer_project_no?: string;
  detailed_step_changes?: DetailedStepChange;
  detailed_step_predictions?: DetailedStepPrediction;
  drawing_no?: string;
  end_date?: Date;
  erfq_no: string;
  itemgroup_key?: string;
  material?: string;
  part_description?: string;
  project_id: number;
  quoting_file_link?: string;
  sourcing_plant?: string;
  start_date: Date;
  state: ProjectRead.StateEnum;
  step?: number;
  step_wait_for_response_last_updated?: Date;
  update_date?: Date;
}
export namespace ProjectRead {
  export type StateEnum = 'open' | 'closed' | 'canceled';
  export const StateEnum = {
    Open: 'open' as StateEnum,
    Closed: 'closed' as StateEnum,
    Canceled: 'canceled' as StateEnum,
  };
}
