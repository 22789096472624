import { InjectionToken, Provider } from '@angular/core';
import * as fromApp from './+state/app.reducer';
import { StoreConfig } from '@ngrx/store';
import { AppAction } from './+state/app.actions';
import { LocalStorageService, storageMetaReducer } from '@clients/helpers';

export const APP_STORAGE_KEYS = new InjectionToken<keyof fromApp.AppState[]>(
  'AppStorageKeys'
);
export const APP_LOCAL_STORAGE_KEY = new InjectionToken<string[]>('AppStorage');
export const APP_CONFIG_TOKEN = new InjectionToken<
  StoreConfig<fromApp.AppState, AppAction>
>('AppConfigToken');

export function getAppConfig(
  saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService
) {
  return {
    metaReducers: [
      storageMetaReducer(saveKeys, localStorageKey, storageService),
    ],
  };
}

export const STORAGE_PROVIDERS: Provider[] = [
  { provide: APP_LOCAL_STORAGE_KEY, useValue: '__bc_app_storage__' },
  { provide: APP_STORAGE_KEYS, useValue: ['cookiesState', 'userEmail'] },
  {
    provide: APP_CONFIG_TOKEN,
    deps: [APP_STORAGE_KEYS, APP_LOCAL_STORAGE_KEY, LocalStorageService],
    useFactory: getAppConfig,
  },
];
