/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ProjectTrend {
  average_days?: number;
  closed_quotes?: number;
  last_state_change?: Date;
  open_quotes?: number;
  trend_open_quotes?: ProjectTrend.TrendOpenQuotesEnum;
  trend_performance?: ProjectTrend.TrendPerformanceEnum;
}
export namespace ProjectTrend {
  export type TrendOpenQuotesEnum = 'rising' | 'falling' | 'consistent';
  export const TrendOpenQuotesEnum = {
    Rising: 'rising' as TrendOpenQuotesEnum,
    Falling: 'falling' as TrendOpenQuotesEnum,
    Consistent: 'consistent' as TrendOpenQuotesEnum,
  };
  export type TrendPerformanceEnum = 'rising' | 'falling' | 'consistent';
  export const TrendPerformanceEnum = {
    Rising: 'rising' as TrendPerformanceEnum,
    Falling: 'falling' as TrendPerformanceEnum,
    Consistent: 'consistent' as TrendPerformanceEnum,
  };
}
