<ng-container *transloco="let t; read: 'api.expired'">
  <h1 mat-dialog-title>{{ data.email }}</h1>

  <div mat-dialog-content>
    <p>{{ data.message }}</p>
  </div>

  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false" (click)="logout()">
      {{ t('logout') }}
    </button>
    <div fxFlex="1 1 auto"></div>
    <button
      mat-raised-button
      cdkFocusInitial
      color="primary"
      (click)="refresh()"
    >
      {{ t('refresh') }}
    </button>
  </div>
</ng-container>
