<div
  *ngIf="thumbnail$ | apiImage | async as image; else elseTemplate"
  [style.background-image]="image.styleUrl"
  (click)="openDrawing()"
>
  <img [src]="image.srcUrl" />
</div>
<ng-template #elseTemplate>
  <div fxLayout fxLayoutAlign="center">
    <span *ngIf="fallback">{{ fallback }}</span>
    <mat-icon *ngIf="!fallback">broken_image</mat-icon>
  </div>
</ng-template>
