import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PlantSustainabilityByMonthModel } from '@clients/api';
import { TranslocoService } from '@ngneat/transloco';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
} from 'ng-apexcharts';
import { UnsubscribeComponent } from '@clients/helpers';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'emission-intensity-card',
  templateUrl: './emission-intensity-card.component.html',
  styleUrls: ['./emission-intensity-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmissionIntensityCardComponent extends UnsubscribeComponent {
  @Input() set currency(currency: String) {
    this.emissionIntensityTitle = {
      text: this.transloco.translate(
        'app.plantSustainabilityPdf.emissionIntensity.title',
        {
          localCurrency: currency,
        }
      ),
    };
  }

  @Input() set sustainability(
    sustainability: PlantSustainabilityByMonthModel[]
  ) {
    this._sustainability = sustainability;
    if (this._sustainability != undefined) {
      this.emissionIntensitySeries = [
        {
          name: '',
          color: '#2b5689',
          data: this._sustainability
            .filter((x) => x.Co2EmissionIntensityKgPer1kCurrency != null)
            .map((o) => ({
              x: `${o.Year != null ? o.Year.toString() : ''}-${
                o.Month != null ? this.numberPipe.transform(o.Month, '2.0') : ''
              }`,
              y: o.Co2EmissionIntensityKgPer1kCurrency,
            })),
        },
      ];
    }
  }

  constructor(
    private transloco: TranslocoService,
    private numberPipe: DecimalPipe
  ) {
    super();
    this.emissionIntensityTitle = {
      text: transloco.translate(
        'app.plantSustainabilityPdf.emissionIntensity.title',
        {
          localCurrency: this.currency,
        }
      ),
    };
  }

  _sustainability?: PlantSustainabilityByMonthModel[];

  emissionIntensitySeries?: ApexAxisChartSeries;
  emissionIntensityTitle?: ApexTitleSubtitle;
  chart: ApexChart = {
    type: 'bar',
    animations: { enabled: false },
    toolbar: { show: false },
    width: '100%',
  };
  tooltip: ApexTooltip = {
    enabled: true,
    y: {
      formatter(val: number, opts?: any): string {
        return val.toLocaleString();
      },
    },
  };
  grid: ApexGrid = {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  };
  xaxis: ApexXAxis = {
    type: 'category',
  };
  yaxis: ApexYAxis = {
    labels: {
      padding: 5,
      formatter(val: number, opts?: any): string {
        return val.toLocaleString();
      },
    },
  };
  dataLabels: ApexDataLabels = {
    enabled: false,
    background: {
      padding: 0,
    },
  };
}
