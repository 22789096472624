<form
  [formGroup]="resetForm"
  fxLayout="column"
  (ngSubmit)="reset()"
  *transloco="let t; read: 'app'"
>
  <mat-card>
    <mat-card-content fxLayout="column" fxLayoutGap="16px">
      <mat-form-field>
        <input
          matInput
          [placeholder]="t('login.email')"
          formControlName="email"
          type="email"
        />
        <mat-error *ngIf="resetForm.controls['email'].hasError('required')">
          {{ t('general.mandatory') }}
        </mat-error>
        <mat-error
          *ngIf="resetForm.controls['email'].hasError('auth/invalid-email')"
        >
          {{ t('login.invalidEmail') }}
        </mat-error>
        <mat-error
          *ngIf="resetForm.controls['email'].hasError('auth/user-not-found')"
        >
          {{ t('login.passwordReset.noUser') }}
        </mat-error>
      </mat-form-field>
    </mat-card-content>

    <mat-card-actions>
      <button mat-button type="submit">
        {{ t('login.passwordReset.reset') }}
      </button>
      <div fxFlex="1 1 auto"></div>
      <button mat-button (click)="back.emit()">
        {{ t('general.back') }}
      </button>
    </mat-card-actions>
  </mat-card>
</form>
