import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap, take, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { AccountExpiredDialogComponent } from './account-expired-dialog/account-expired-dialog.component';

@Injectable()
export class ErrorSnackbarInterceptor implements HttpInterceptor {
  private offlineMessage?: string;
  private isexpired: boolean = false;

  constructor(
    private router: Router,
    private angularFireAuth: AngularFireAuth,
    private transloco: TranslocoService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.startsWith('/assets/i18n')) {
      // Hide error snack bar on missing translations
      return next.handle(req);
    }

    if (!this.offlineMessage) {
      this.transloco
        .selectTranslate('error.offline', undefined, 'api')
        .pipe(take(1))
        .subscribe((message: string) => {
          this.offlineMessage = message;
        });
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Blob) {
          return this.blobToJson(err.error).pipe(
            mergeMap((newError: {}) => {
              const error = { ...err, error: newError };
              return this.handleError(error);
            })
          );
        }

        return this.handleError(err);
      })
    );
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    switch (err.status) {
      // Offline
      case 0:
      // Timeout
      case 504: {
        if (this.offlineMessage) {
          this.showWarnSnack(this.offlineMessage);
        } else {
          // loading translation not possible; use fallback
          this.showWarnSnack(
            'You are offline. Check your connection and try again.'
          );
        }
        return throwError(err);
      }
      case 401: {
        return this.transloco
          .selectTranslate('error.authRequired', undefined, 'api')
          .pipe(
            tap((message: string) => this.showWarnSnack(message)),
            tap(() => {
              this.angularFireAuth
                .signOut()
                .then(() => this.router.navigate(['/login']));
            }),
            mergeMap(() => throwError(err))
          );
      }
      case 404: {
        return throwError(err);
      }
      default: {
        this.handleCustomError(err);
      }
    }

    return throwError(err);
  }

  private handleCustomError(err: HttpErrorResponse) {
    if (err.error && err.error.key && err.error.user_msg && err.error.data) {
      if (this.isAccountExpiredKey(err.error.key)) {
        if (!this.isexpired) {
          this.showAccountExpired(err.error.user_msg);
          this.isexpired = true;
        }
      } else {
        this.showWarnSnack(err.error.user_msg);
      }
    } else if (err.error?.Key && err.error?.DefaultMessage) {
      if (
        (err.url?.endsWith('/FiltersContact/mycontact') &&
          err.error.Key === 'RESOURCE_DOES_NOT_EXIST') ||
        (err.url?.endsWith('/image') &&
          err.error.Key === 'RESOURCE_DOES_NOT_EXIST')
      ) {
        // Do not show since a not existing contact should have the possibility to still order filters
        return;
      } else if (this.isAccountExpiredKey(err.error.Key)) {
        if (!this.isexpired) {
          this.showAccountExpired(err.error.DefaultMessage);
          this.isexpired = true;
        }
      } else {
        this.showWarnSnack(err.error.DefaultMessage);
      }
    } else {
      this.transloco
        .selectTranslate('error.internal', undefined, 'api')
        .subscribe(
          (message: string) => this.showWarnSnack(message),
          mergeMap(() => throwError(err))
        );
    }
  }

  private isAccountExpiredKey(key: string) {
    return key == 'ACCOUNT_EXPIRED_EXCEPTION';
  }

  private showAccountExpired(text: string) {
    this.angularFireAuth.currentUser.then((u) => {
      if (u?.email != null) {
        this.dialog
          .open(AccountExpiredDialogComponent, {
            data: { email: u.email, message: text },
            closeOnNavigation: true,
            disableClose: true,
          })
          .afterClosed()
          .subscribe(() => {
            this.isexpired = false;
          });
      }
    });
  }

  private showWarnSnack(message: string) {
    console.log(message);
    this.transloco
      .selectTranslate('app.general.close')
      .subscribe((close: string) =>
        this.snackBar.open(message, close, {
          duration: 5000,
          panelClass: 'error-snack',
        })
      );
  }

  private blobToJson(blob: Blob): Observable<{}> {
    return new Observable((obs) => {
      const reader = new FileReader();

      reader.onerror = (err) => obs.error(err);
      reader.onabort = (err) => obs.error(err);
      reader.onload = () => {
        try {
          obs.next(JSON.parse(reader.result as string));
        } catch {
          obs.next({});
        }
      };
      reader.onloadend = () => obs.complete();

      return reader.readAsText(blob);
    });
  }
}
