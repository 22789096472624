import { Action } from '@ngrx/store';
import { CustomerGroupRead } from '@clients/api';
import { LocationFilter } from './customer.reducer';

export enum CustomerActionTypes {
  LoadCustomerGroups = '[Customer] Load CustomerGroups',
  CustomerGroupsLoaded = '[Customer] CustomerGroups Loaded',
  SelectCustomerGroup = '[Customer] Select CustomerGroup',
  SetFilter = '[Customer] Set Filter',
}

export class LoadCustomerGroups implements Action {
  readonly type = CustomerActionTypes.LoadCustomerGroups;
}

export class CustomerGroupsLoaded implements Action {
  readonly type = CustomerActionTypes.CustomerGroupsLoaded;
  constructor(public groups: CustomerGroupRead[]) {}
}

export class SelectCustomerGroup implements Action {
  readonly type = CustomerActionTypes.SelectCustomerGroup;
  constructor(public customergroupId: number) {}
}

export class SetFilter implements Action {
  readonly type = CustomerActionTypes.SetFilter;
  constructor(public filter: LocationFilter) {}
}

export type CustomerAction =
  | LoadCustomerGroups
  | CustomerGroupsLoaded
  | SelectCustomerGroup
  | SetFilter;

export const fromCustomerActions = {
  LoadCustomerGroups,
  CustomerGroupsLoaded,
  SelectCustomerGroup,
  SetFilter,
};
