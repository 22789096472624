import { NgModule, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule, StoreConfig } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCustomer from './+state/customer.reducer';
import { CustomerEffects } from './+state/customer.effects';
import { CustomerFacade } from './+state/customer.facade';
import { CustomerAction } from './+state/customer.actions';
import { LocalStorageService, storageMetaReducer } from '@clients/helpers';

export const CUSTOMER_STORAGE_KEYS = new InjectionToken<
  keyof fromCustomer.CustomerState[]
>('CustomerStorageKeys');
export const CUSTOMER_LOCAL_STORAGE_KEY = new InjectionToken<string[]>(
  'CustomerStorage'
);
export const CUSTOMER_CONFIG_TOKEN = new InjectionToken<
  StoreConfig<fromCustomer.CustomerState, CustomerAction>
>('CustomerConfigToken');

export function getCustomerConfig(
  saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService
) {
  return {
    metaReducers: [
      storageMetaReducer(saveKeys, localStorageKey, storageService),
    ],
  };
}

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCustomer.CUSTOMER_FEATURE_KEY,
      fromCustomer.reducer,
      CUSTOMER_CONFIG_TOKEN
    ),
    EffectsModule.forFeature([CustomerEffects]),
  ],
  providers: [
    CustomerFacade,
    {
      provide: CUSTOMER_LOCAL_STORAGE_KEY,
      useValue: '__bc_customer_storage__',
    },
    {
      provide: CUSTOMER_STORAGE_KEYS,
      useValue: ['customerGroups.selected', 'kpiFilters'],
    },
    {
      provide: CUSTOMER_CONFIG_TOKEN,
      deps: [
        CUSTOMER_STORAGE_KEYS,
        CUSTOMER_LOCAL_STORAGE_KEY,
        LocalStorageService,
      ],
      useFactory: getCustomerConfig,
    },
  ],
})
export class CustomerModule {}
