import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { LoginControllerService } from '@clients/api';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'clients-delete-account-dialog',
  templateUrl: './delete-account-dialog.component.html',
  styleUrls: ['./delete-account-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteAccountDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<DeleteAccountDialogComponent>,
    private router: Router,
    private angularFireAuth: AngularFireAuth,
    private loginControllerService: LoginControllerService
  ) {}

  delete() {
    this.loginControllerService.deleteMyAccountUsingDELETE({}).subscribe(() => {
      this.angularFireAuth.signOut().then(() => {
        this.router.navigate(['/login']);
        this.dialogRef.close('true');
      });
    });
  }
}
