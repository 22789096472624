import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppPartialState, CookiesState } from './app.reducer';
import { appQuery } from './app.selectors';
import {
  LoadApp,
  SetEmail,
  StartAppInitializer,
  UpdateCookiesState,
} from './app.actions';
import { Observable } from 'rxjs';

@Injectable()
export class AppFacade {
  loaded$: Observable<boolean> = this.store.pipe(select(appQuery.getLoaded));
  cookiesState$: Observable<CookiesState> = this.store.pipe(
    select(appQuery.getCookiesState)
  );
  userEmail$: Observable<string | undefined> = this.store.pipe(
    select(appQuery.getEmail)
  );

  constructor(private store: Store<AppPartialState>) {}

  startAppInitializer() {
    this.store.dispatch(new StartAppInitializer());
  }

  loadApp() {
    this.store.dispatch(new LoadApp());
  }

  setEmail(email?: string) {
    this.store.dispatch(new SetEmail(email));
  }

  updateCookiesState(state: CookiesState) {
    this.store.dispatch(new UpdateCookiesState(state));
  }
}
