<ng-container *transloco="let t; read: 'app'">
  <h1 mat-dialog-title>{{ t('menu.settings.deleteAccount') }}</h1>

  <div mat-dialog-content>
    <p>{{ t('menu.settings.text') }}</p>
  </div>

  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial>
      {{ t('general.cancel') }}
    </button>
    <div fxFlex="1 1 auto"></div>
    <button mat-raised-button color="warn" (click)="delete()">
      {{ t('menu.settings.confirmDelete') }}
    </button>
  </div>
</ng-container>
