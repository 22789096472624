/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
  providedIn: 'root',
})
export class DrawingControllerService {
  protected basePath =
    'http://api.bc.gknpm.fortysix.world/business-connect-api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * deleteDrawing
   * @param erfq_no erfq_no
   * @param Authorization
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteDrawingUsingDELETE(
    params: { erfq_no: string; Authorization?: string },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteDrawingUsingDELETE(
    params: { erfq_no: string; Authorization?: string },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteDrawingUsingDELETE(
    params: { erfq_no: string; Authorization?: string },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteDrawingUsingDELETE(
    params: { erfq_no: string; Authorization?: string },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const erfq_no: string = params.erfq_no;
    const Authorization: string | undefined = params.Authorization;

    if (erfq_no === null || erfq_no === undefined) {
      throw new Error(
        'Required parameter erfq_no was null or undefined when calling deleteDrawingUsingDELETE.'
      );
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.delete<any>(
      `${this.configuration.basePath}/project/${encodeURIComponent(
        String(erfq_no)
      )}/drawing`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * getDrawing
   * @param customergroup_id customergroup_id
   * @param project_id project_id
   * @param Authorization
   * @param max_height max_height
   * @param max_width max_width
   * @param quality quality
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDrawingUsingGET(
    params: {
      customergroup_id: number;
      project_id: number;
      Authorization?: string;
      max_height?: number;
      max_width?: number;
      quality?: number;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<string>;
  public getDrawingUsingGET(
    params: {
      customergroup_id: number;
      project_id: number;
      Authorization?: string;
      max_height?: number;
      max_width?: number;
      quality?: number;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public getDrawingUsingGET(
    params: {
      customergroup_id: number;
      project_id: number;
      Authorization?: string;
      max_height?: number;
      max_width?: number;
      quality?: number;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public getDrawingUsingGET(
    params: {
      customergroup_id: number;
      project_id: number;
      Authorization?: string;
      max_height?: number;
      max_width?: number;
      quality?: number;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const customergroup_id: number = params.customergroup_id;
    const project_id: number = params.project_id;
    const Authorization: string | undefined = params.Authorization;
    const max_height: number | undefined = params.max_height;
    const max_width: number | undefined = params.max_width;
    const quality: number | undefined = params.quality;

    if (customergroup_id === null || customergroup_id === undefined) {
      throw new Error(
        'Required parameter customergroup_id was null or undefined when calling getDrawingUsingGET.'
      );
    }
    if (project_id === null || project_id === undefined) {
      throw new Error(
        'Required parameter project_id was null or undefined when calling getDrawingUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (max_height !== undefined && max_height !== null) {
      queryParameters = queryParameters.set('max_height', <any>max_height);
    }
    if (max_width !== undefined && max_width !== null) {
      queryParameters = queryParameters.set('max_width', <any>max_width);
    }
    if (quality !== undefined && quality !== null) {
      queryParameters = queryParameters.set('quality', <any>quality);
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'image/png',
      'image/jpeg',
      'image/webp',
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.get<string>(
      `${this.configuration.basePath}/customer-group/${encodeURIComponent(
        String(customergroup_id)
      )}/project/${encodeURIComponent(String(project_id))}/drawing`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * uploadDrawing
   * @param erfq_no erfq_no
   * @param Authorization
   * @param name name
   * @param file file
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public uploadDrawingUsingPUT(
    params: {
      erfq_no: string;
      Authorization?: string;
      name?: string;
      file?: Blob;
    },
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public uploadDrawingUsingPUT(
    params: {
      erfq_no: string;
      Authorization?: string;
      name?: string;
      file?: Blob;
    },
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public uploadDrawingUsingPUT(
    params: {
      erfq_no: string;
      Authorization?: string;
      name?: string;
      file?: Blob;
    },
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public uploadDrawingUsingPUT(
    params: {
      erfq_no: string;
      Authorization?: string;
      name?: string;
      file?: Blob;
    },
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const erfq_no: string = params.erfq_no;
    const Authorization: string | undefined = params.Authorization;
    const name: string | undefined = params.name;
    const file: Blob | undefined = params.file;

    if (erfq_no === null || erfq_no === undefined) {
      throw new Error(
        'Required parameter erfq_no was null or undefined when calling uploadDrawingUsingPUT.'
      );
    }

    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (name !== undefined && name !== null) {
      queryParameters = queryParameters.set('name', <any>name);
    }

    let headers = this.defaultHeaders;
    if (Authorization !== undefined && Authorization !== null) {
      headers = headers.set('Authorization', String(Authorization));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    let convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: this.encoder });
    }

    if (file !== undefined) {
      formParams = (formParams.append('file', <any>file) as any) || formParams;
    }

    return this.httpClient.put<any>(
      `${this.configuration.basePath}/project/${encodeURIComponent(
        String(erfq_no)
      )}/drawing`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
