<ng-container *transloco="let t; read: 'app'">
  <div class="page-content" *ngIf="plantSustainabilityData !== undefined">
    <div class="header">
      <div class="header-part">
        <small>
          <img src="/assets/imgs/gkn-pm-horizontal-dark-blue.svg" alt="gkn logo" />
        </small>

        <div
          class="chart-descriptions"
          *transloco="let t; read: 'app.plantSustainabilityPdf'"
        >
          <p class="chart-descriptions-title">
            {{ t('energyConsumption.title') }}
          </p>
          <p class="chart-descriptions-description">
            {{ t('energyConsumption.description') }}
          </p>
          <p class="chart-descriptions-title">
            {{
              t('emissionIntensity.title', {
                localCurrency: plantSustainabilityData.Site.LocalCurrency
              })
            }}
          </p>
          <p class="chart-descriptions-description">
            {{ t('emissionIntensity.description') }}
          </p>
        </div>
      </div>
      <div class="header-part">
        <div class="plant-informations">
          <p class="site-name">{{ plantSustainabilityData.Site.Name }}</p>
          <div class="address-infos">
            <p>{{ plantSustainabilityData.Site.AddressLine1 }}</p>
            <p>{{ plantSustainabilityData.Site.AddressLine2 }}</p>
            <p>{{ plantSustainabilityData.Site.AddressLine3 }}</p>
            <p>{{ plantSustainabilityData.Site.AddressLine4 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="date">
        <span>Datum {{ date | date: 'dd.MM.yyyy' }}</span>
      </div>
      <div class="charts">
        <energy-consumtion-card
          class="chart"
          [sustainability]="
            plantSustainabilityData.PlantSustainabilitiesByMonth
          "
        >
        </energy-consumtion-card>
        <emission-intensity-card
          class="chart"
          [sustainability]="
            plantSustainabilityData.PlantSustainabilitiesByMonth
          "
          [currency]="plantSustainabilityData.Site.LocalCurrency"
        >
        </emission-intensity-card>
      </div>
    </div>
  </div>
</ng-container>
