import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { v4 as uuid } from 'uuid';
import { Observable, of, from } from 'rxjs';
import {
  map,
  mergeMap,
  catchError,
  publishReplay,
  refCount,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private readonly DEVICE_TOKEN_KEY: string = '__bc_device_token__';

  private deviceToken: string | null = null;
  private webPSupport?: Observable<boolean>;

  deviceName: string;
  isBrowser: boolean;
  isMobile: boolean;

  constructor(private platform: Platform) {
    this.deviceName = navigator.userAgent;
    this.isBrowser = this.platform.isBrowser;
    this.isMobile = this.platform.ANDROID || this.platform.IOS;
  }

  getAlpha2Language(): string {
    const language: string =
      (navigator as any).userLanguage ||
      (navigator as any).browserLanguage ||
      navigator.language;
    return language.split('-')[0];
  }

  getDeviceToken(): string {
    this.deviceToken = localStorage.getItem(this.DEVICE_TOKEN_KEY);
    if (this.deviceToken === null) {
      this.deviceToken = uuid();
      localStorage.setItem(this.DEVICE_TOKEN_KEY, this.deviceToken as string);
    }

    return this.deviceToken as string;
  }

  getWebPSupport(): Observable<boolean> {
    if (!this.webPSupport) {
      // If the browser doesn't has the method createImageBitmap, you can't display webp format
      if (!window.createImageBitmap) {
        this.webPSupport = of(false);
      } else {
        // Base64 representation of a white point image
        const webpdata =
          'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';

        // Retrieve the Image in Blob Format
        this.webPSupport = from(fetch(webpdata)).pipe(
          mergeMap((response: Response) => from(response.blob())),
          // If the createImageBitmap method succeeds, return true, otherwise false
          mergeMap((blob: Blob) => from(createImageBitmap(blob))),
          map(() => true),
          catchError(() => of(false)),
          // Cache result
          publishReplay(1),
          refCount()
        );
      }
    }

    return this.webPSupport;
  }
}
