/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ConcernRead {
  close_date?: Date;
  concern_id?: number;
  concern_state?: ConcernRead.ConcernStateEnum;
  customer_claimed_plan?: string;
  customer_claimed_plant?: string;
  customer_concern_number?: string;
  customer_information?: string;
  customerpart_number?: string;
  defect_detail_type?: string;
  is_escalated: boolean;
  item?: string;
  item_description?: string;
  key: string;
  last_updated?: Date;
  open_date: Date;
  problem_description?: string;
  responsible_email?: string;
  responsible_name?: string;
  responsible_phone?: string;
  responsible_role?: string;
  severity?: number;
  site?: string;
  step1_close_date?: Date;
  step1_open_date?: Date;
  step1_state?: ConcernRead.Step1StateEnum;
  step1_target_date?: Date;
  step2_close_date?: Date;
  step2_open_date?: Date;
  step2_state?: ConcernRead.Step2StateEnum;
  step2_target_date?: Date;
  step3_close_date?: Date;
  step3_open_date?: Date;
  step3_state?: ConcernRead.Step3StateEnum;
  step3_target_date?: Date;
  step4_close_date?: Date;
  step4_open_date?: Date;
  step4_state?: ConcernRead.Step4StateEnum;
  step4_target_date?: Date;
  target_date?: Date;
}
export namespace ConcernRead {
  export type ConcernStateEnum =
    | 'open_in_time'
    | 'done_delayed'
    | 'done_in_time'
    | 'open_delayed';
  export const ConcernStateEnum = {
    OpenInTime: 'open_in_time' as ConcernStateEnum,
    DoneDelayed: 'done_delayed' as ConcernStateEnum,
    DoneInTime: 'done_in_time' as ConcernStateEnum,
    OpenDelayed: 'open_delayed' as ConcernStateEnum,
  };
  export type Step1StateEnum =
    | 'open_in_time'
    | 'done_delayed'
    | 'done_in_time'
    | 'open_delayed';
  export const Step1StateEnum = {
    OpenInTime: 'open_in_time' as Step1StateEnum,
    DoneDelayed: 'done_delayed' as Step1StateEnum,
    DoneInTime: 'done_in_time' as Step1StateEnum,
    OpenDelayed: 'open_delayed' as Step1StateEnum,
  };
  export type Step2StateEnum =
    | 'open_in_time'
    | 'done_delayed'
    | 'done_in_time'
    | 'open_delayed';
  export const Step2StateEnum = {
    OpenInTime: 'open_in_time' as Step2StateEnum,
    DoneDelayed: 'done_delayed' as Step2StateEnum,
    DoneInTime: 'done_in_time' as Step2StateEnum,
    OpenDelayed: 'open_delayed' as Step2StateEnum,
  };
  export type Step3StateEnum =
    | 'open_in_time'
    | 'done_delayed'
    | 'done_in_time'
    | 'open_delayed';
  export const Step3StateEnum = {
    OpenInTime: 'open_in_time' as Step3StateEnum,
    DoneDelayed: 'done_delayed' as Step3StateEnum,
    DoneInTime: 'done_in_time' as Step3StateEnum,
    OpenDelayed: 'open_delayed' as Step3StateEnum,
  };
  export type Step4StateEnum =
    | 'open_in_time'
    | 'done_delayed'
    | 'done_in_time'
    | 'open_delayed';
  export const Step4StateEnum = {
    OpenInTime: 'open_in_time' as Step4StateEnum,
    DoneDelayed: 'done_delayed' as Step4StateEnum,
    DoneInTime: 'done_in_time' as Step4StateEnum,
    OpenDelayed: 'open_delayed' as Step4StateEnum,
  };
}
