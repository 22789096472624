import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { emailVerified } from '@angular/fire/auth-guard';

export const redirectUnverifiedToLogin = () => redirectUnverifiedTo(['login']);
export const redirectUnverifiedTo = (redirect: any[]) =>
  pipe(
    emailVerified,
    map((verified) => verified || redirect)
  );

export const redirectVerifiedToMenu = () => redirectVerifiedTo(['menu']);
export const redirectVerifiedTo = (redirect: any[]) =>
  pipe(
    emailVerified,
    map((verified) => (verified && redirect) || true)
  );
