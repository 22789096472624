<!--has to be a footer due tue strange behavior with ios 15 floating addressbar-->
<footer
  class="full-height background"
  [gdRows]="headerRef ? 'auto 1fr auto' : '1fr auto'"
>
  <div *ngIf="headerRef" class="header">
    <ng-container [ngTemplateOutlet]="headerRef"></ng-container>
  </div>

  <!-- scroll area -->
  <div class="scroll" fxLayout="column">
    <!-- sub header -->
    <div *ngIf="subheaderRef">
      <ng-container [ngTemplateOutlet]="subheaderRef"></ng-container>
    </div>

    <!-- sticky header -->
    <div *ngIf="stickyheaderRef || loading !== undefined" class="sticky-top">
      <ng-container [ngTemplateOutlet]="stickyheaderRef"></ng-container>
      <mat-progress-bar
        *ngIf="loading !== undefined"
        [mode]="loading ? 'indeterminate' : 'determinate'"
        [color]="loadingColor"
        value="100"
      ></mat-progress-bar>
    </div>

    <!-- content -->
    <div
      *ngIf="contentRef"
      fxFlex="1 1 auto"
      class="mat-app-background layout-content"
    >
      <div class="content-width" *ngIf="limitContentWidth">
        <ng-container [ngTemplateOutlet]="contentRef"></ng-container>
      </div>

      <ng-container
        *ngIf="!limitContentWidth"
        [ngTemplateOutlet]="contentRef"
      ></ng-container>
    </div>
  </div>

  <div *ngIf="footerRef" class="mat-app-background">
    <ng-container [ngTemplateOutlet]="footerRef"></ng-container>
  </div>
</footer>
