<mat-card *ngIf="_sustainability" class="stockcard">
  <mat-card-content
    fxLayout="column"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
  >
    <div gdAreas="chart chart" gdColumns="1fr 1fr" gdGap="16px">
      <apx-chart
        class="chart"
        gdArea="chart"
        [series]="energyConsumptionSeries"
        [chart]="chart"
        [xaxis]="xaxis"
        [yaxis]="yaxis"
        [tooltip]="tooltip"
        [title]="energyConsumptionTitle"
        [dataLabels]="dataLabels"
        [grid]="grid"
      ></apx-chart>
      <div class="noData" *ngIf="_sustainability.length === 0">
        <span
          *transloco="
            let t;
            read: 'app.plantSustainabilityPdf.energyConsumption'
          "
        >
          {{ t('noData') }}
        </span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
