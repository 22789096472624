import { ActionReducer, Action, UPDATE, INIT } from '@ngrx/store';
import { ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { RECOMPUTE } from '@ngrx/store-devtools';
import { merge, pick } from 'lodash-es';
import { LocalStorageService } from './local-storage.service';

const excludes: string[] = [INIT, ROOT_EFFECTS_INIT, RECOMPUTE];

// copy of https://medium.com/better-programming/sync-your-state-in-local-storage-with-ngrx-9d6ceba93fc0
// with slight changes

export function storageMetaReducer<S, A extends Action = Action>(
  saveKeys: string[],
  localStorageKey: string,
  storageService: LocalStorageService
) {
  let onInit = true;
  return function (reducer: ActionReducer<S, A>) {
    return function (state: S, action: A): S {
      // get to the nextState.
      const nextState = reducer(state, action);
      // init the application state.
      if (onInit && action.type === UPDATE) {
        onInit = false;
        const savedState = storageService.getSavedState(localStorageKey);
        return merge(nextState, savedState);
      }

      if (!excludes.includes(action.type)) {
        // save the next state to the application storage.
        const stateToSave = pick(nextState, saveKeys);
        storageService.setSavedState(stateToSave, localStorageKey);
      }

      return nextState;
    };
  };
}
