import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'clients-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordResetComponent {
  @Input() set email(email: string) {
    this.resetForm.controls.email.setValue(email);
  }

  @Output() back: EventEmitter<void> = new EventEmitter<void>();
  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  resetForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private angularFireAuth: AngularFireAuth,
    private snackbar: MatSnackBar,
    private transloco: TranslocoService
  ) {
    this.resetForm = this.formBuilder.group({
      email: this.formBuilder.control(
        null,
        Validators.compose([Validators.required])
      ),
    });
  }

  async reset() {
    if (this.resetForm.invalid) {
      return;
    }

    this.loading.emit(true);
    try {
      await this.angularFireAuth.sendPasswordResetEmail(
        this.resetForm.controls.email.value
      );

      this.snackbar.open(
        this.transloco.translate('app.login.passwordReset.emailSent'),
        this.transloco.translate('app.general.close'),
        {
          duration: 3000,
        }
      );
      this.back.emit();
    } catch (err) {
      this.handleError(err);
    }
    this.loading.emit(false);
  }

  private handleError(err: any) {
    switch (err.code) {
      // Thrown if the email address is not valid.
      case 'auth/invalid-email': {
        this.resetForm.controls.email.setErrors({
          'auth/invalid-email': true,
        });
        break;
      }
      // Thrown if there is no user corresponding to the email address.
      case 'auth/user-not-found': {
        this.resetForm.controls.email.setErrors({
          'auth/user-not-found': true,
        });
        break;
      }
      default: {
        console.error(`46 unhandled: ${err}`);
      }
    }
  }
}
